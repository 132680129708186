.webinar-card {
	display: flex;
	margin-bottom: -30px;
}

.webinar-card__img {
	margin-bottom: 30px;
	width: 35%;
	min-width: 35%;
}

.webinar-card__content {
	position: relative;
	margin-bottom: 30px;
	flex-grow: 1;
	padding-left: 50px;
	padding-right: 30px;
	&:before {
		position: absolute;
		top: 98px;
		right: 0;
		content: '';
		width: 4px;
		height: 200px;
		background-color: #d9d9d9;
	}
}

.webinar-card__list {
	margin-bottom: 30px;
	min-width: 160px;
	padding-top: 94px;
	padding-left: 30px;
}

.webinar-card__top {
	display: flex;
	align-items: center;
	height: 98px;
	padding: 12px 0;
}

.webinar-card__date {
	// margin-top: 10px;
	margin-right: 20px;
	margin-bottom: 10px;
	font-family: Bebas, sans-serif;
	font-size: 44px;
	color: #0570ab;
}

.webinar-card__time {
	margin-top: 6px;
	margin-bottom: 10px;
	font-family: Bebas, sans-serif;
	line-height: 46px;
	font-size: 30px;
	color: #999;
}

.webinar-card__header {
	display: flex;
	margin-left: -50px;
	margin-bottom: 16px;
	padding: 12px 30px 30px 50px;
	color: #fff;
	background-color: #c8e7f1;
	transition: background-color .3s;
	&:hover {
		color: #fff;
		background-color: darken(#c8e7f1, 10%);
	}
}

.webinar-card__btn {
	padding: 0 12px;
	font-size: 32px;
}

.webinar-card__label {
	margin-bottom: 6px;
	font-size: 24px;
}

.webinar-card__title {
	font-family: Bebas, sans-serif;
	// font-size: 48px;
	font-size: 34px;
	color: #a8272e;
	text-transform: uppercase;
}

.webinar-card__duration {
	align-self: flex-end;
	font-family: Bebas, sans-serif;
	margin-top: 20px;
	// font-size: 48px;
	font-size: 34px;
	white-space: nowrap;
}

.webinar-card__name {
	margin-bottom: 20px;
	font-size: 24px;
	color: #0570ab;
	text-transform: uppercase;
	&:before {
		display: block;
		content: '';
		margin-bottom: 16px;
		width: 100%;
		max-width: 320px;
		height: 3px;
		background-color: currentColor;
	}
}

.webinar-card__text {
	font-size: 18px;
	color: #484847;
}

.webinar-list-label {
	margin-bottom: 26px;
	font-size: 18px;
	color: #a8272e;
	text-transform: uppercase;
}

.webinar-item {
	display: block;
	margin-left: -10px;
	margin-bottom: 10px;
	padding: 10px;
	transition: background-color .3s;
	&:hover {
		background-color: #c8e7f1;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.webinar-item__date,
.webinar-item__name {
	font-size: 18px;
	color: #484847;
}

.webinar-item__title {
	font-size: 18px;
	color: #0570ab;
	text-transform: uppercase;
}
