.popup {
	display: none;
	width: 100%;
	max-width: 400px;
	padding: 30px 40px;
	border-radius: 10px;
	box-shadow: 0 0 38px rgba(19, 19, 19, .16);
	.btn {
		padding: 0 32px;
		height: 40px;
	}
}

// .popup-img {
// 	max-width: 720px;
// 	padding: 40px 20px;
// 	.popup__content {
// 		max-width: 470px;
// 	}
// }

// решить
// .fancybox-button {
// 	display: none;
// }

.popup__close {
	position: absolute;
	top: 8px;
	right: 8px;
	width: 30px;
	height: 30px;
	font-size: 0;
	background-color: transparent;
	cursor: pointer;
	&:before, &:after {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -1px;
		margin-left: -11px;
		content: '';
		width: 22px;
		height: 2px;
		background-color: #6f7375;
		transition: background-color .3s;
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
	&:hover {
		&:before, &:after {
			background-color: red;
		}
	}
}

.popup__tab-callers {
	position: relative;
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -12px;
		margin-left: -1px;
		content: '';
		width: 2px;
		height: 24px;
		background-color: #b1b2b3;
	}
}

.popup__tab-caller {
	font-family: Bebas, sans-serif;
	font-size: 30px;
	color: #b1b2b3;
	text-transform: uppercase;
	transition: color .3s;
	cursor: pointer;
	&:hover, &.active {
		color: #7bcae4;
	}
}

.popup__tab {
	display: none;
	&.active {
		display: block;
	}
}

#popup-thanks {
	max-width: 300px;
}

.thanks__title {
	margin-bottom: 16px;
	text-align: center;
	font-size: 24px;
	color: #a7282e;
}

.thanks__text {
	text-align: center;
	font-size: 16px;
	color: #b1b2b3;
}

// .popup__content {
// 	margin: 0 auto;
// 	max-width: 450px;
// }

// .payment-title {
// 	margin-top: 30px;
// 	margin-bottom: 10px;
// 	font-size: 15px;
// 	text-transform: uppercase;
// 	text-align: center;
// }

// .payment-item {
// 	margin: 4px;
// 	border-radius: 8px;
// 	border: 1px solid transparent;
// 	transition: border-color .3s;
// 	&:hover {
// 		border-color: #ffde01;
// 	}
// }

// // .delivery-check-block {
// // 	display: flex;
// // }

// .delivery-check {
// 	margin-bottom: 0;
// 	input {
// 		display: none;
// 	}
// 	& + .delivery-check {
// 		margin-left: -5px;
// 	}
// }

// .delivery-check input:checked + .delivery-check__btn {
// 	border-color: #ffde01;
// 	z-index: 1;
// }

// .delivery-check__btn {
// 	position: relative;
// 	display: flex;
// 	width: 138px;
// 	height: 54px;
// 	border: 5px solid #dcdcdc;
// 	cursor: pointer;
// 	transition: border-color .3s;
// 	img {
// 		margin: auto;
// 		max-width: 80%;
// 		max-height: 80%;
// 	}
// }

// .delivery-map {
// 	height: 240px;
// 	border: 1px solid #d1d1d1;
// 	background: #dcdcdc center no-repeat;
// 	background-size: cover;
// }






