.seminar-block {
	position: relative;
	padding-left: 25px;
}

.seminar-heading {
	margin-top: 16px;
	margin-bottom: 16px;
	padding-left: 156px;
	font-size: 24px;
	color: #9c9c9c;
	text-transform: uppercase;
}

.seminar-label {
	position: absolute;
	top: -20px;
	left: -15px;
	width: 170px;
	height: 50px;
	line-height: 50px;
	font-family: Bebas, sans-serif;
	font-size: 36px;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	background: #a7272e;

}

.seminar-items {
	column-count: 2;
	column-gap: 30px;
}

.seminar-item {
	display: inline-flex;
	width: 100%;
	color: #484847;
}

.seminar-item__data {
	margin-right: 26px;
	width: 130px;
	min-width: 130px;
	padding-top: 46px;
	padding-right: 12px;
	text-align: right;
	border-right: 3px solid #e1e2e2;
}

.seminar-item__data-inner {
	display: inline-block;
	text-align: left;
}

.seminar-item__content {
	display: flex;
	justify-content: space-between;
	margin-bottom: 36px;
	width: 100%;
	min-height: 96px;
	padding: 26px 26px 18px 18px;
	background-color: #dedfdf;
	transition: background-color .3s;
	&:hover {
		background-color: #c8e7f1;
	}
}

.seminar-item__date {
	line-height: 36px;
	font-size: 36px;
	color: #a7272e;
}

.seminar-item__time {
	font-size: 18px;
}

.seminar-item__title {
	font-family: Bebas, sans-serif;
	font-size: 30px;
	text-transform: uppercase;
}

.seminar-item__name {
	font-size: 18px;
}

.seminar-item__link {
	display: inline-block;
	align-self: flex-end;
	font-family: Bebas, sans-serif;
	// margin-right: -6px;
	margin-bottom: -6px;
	padding: 6px 0;
	font-size: 24px;
	color: #a72930;
	text-transform: uppercase;
	transition: color .3s;
	&:hover {
		color: #fff;
	}
}

// .seminar-item__title {}