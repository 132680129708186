// Файл для переопределения значениий встроенных переменных.

$font-family-base: DINPro, sans-serif;

// Размеры отступов, `m-1` `pb-2` `mx-4` ... можно добавлять/удалять без ограничений.
$spacer: 4px;
$spacers: (
  0: 0,
  1: $spacer,           // 4px
  2: ($spacer * 2),     // 8px
  3: ($spacer * 3),     // 12px
  4: ($spacer * 4),     // 16px
  5: ($spacer * 5),     // 20px
  6: ($spacer * 7.5),   // 30px
  7: ($spacer * 10),    // 40px
  8: ($spacer * 12.5),  // 50px
  9: ($spacer * 15),    // 60px
  10: ($spacer * 17.5), // 70px
  11: ($spacer * 20),   // 80px
  12: ($spacer * 22.5), // 90px
  13: ($spacer * 25)    // 100px
);


$label-margin-bottom: 8px;

// Заголовки
$headings-margin-bottom: 20px;
// $headings-font-family: Comfortaa, serif;
$headings-font-weight: normal;
$headings-color: #a8272e;
// $headings-line-height: 1.5;

$paragraph-margin-bottom: 20px;


// Цвета
$gray-100: #4c4c4c;
$gray-200: #808080;
// $gray-300: #5e5d5d;

// $gray-400: #8d8c8b;
// $gray-500: #8d8c8b;
// $gray-600: #8d8c8b;
// $gray-700: #8d8c8b;
// $gray-800: #8d8c8b;
// $gray-900: #8d8c8b;


// Элементы формы
$input-height: 48px;
// $input-padding-y: 6px;
// $input-padding-x: 21px;
// $input-font-family: ;
$input-font-size: 20px;
$input-font-weight: normal;
$input-color: #24232d;
$input-bg: #f3f4f4;
$input-border-width: 1px; 
$input-border-color: #f3f4f4;
$input-border-radius: 0;

$input-focus-border-color: #7bcae4;
$input-focus-box-shadow: none;

$form-grid-gutter-width: 16px;

// $form-group-margin-bottom: 14px;
$input-placeholder-color: #d4d4d4;

$hr-margin-y: 20px;
$hr-color: #333339;
$hr-opacity: .15;


// Tooltips

// $tooltip-font-size:                 14px;
// $tooltip-max-width:                 200px;
// $tooltip-color:                     #fff;
$tooltip-bg:                        darken(#1876e1, 10%);
// $tooltip-border-radius:             6px;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 10px;
$tooltip-padding-x:                 10px;
// $tooltip-margin:                    0 !default;

$tooltip-arrow-color:               darken(#1876e1, 10%);