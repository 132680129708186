footer {
	padding: 30px 0;
	font-size: 16px;
	color: #fff;
	background-color: #a7282e;
}

.footer__inner {
	margin-bottom: -40px;
	// justify-content: space-between;
	> div {
		max-width: 300px;
		margin-bottom: 40px;
	}
}

.footer-heading {
	margin-bottom: 20px;
	font-family: Bebas, sans-serif;
	font-size: 24px;
	text-transform: uppercase;
}

.footer__nav {
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 16px;
	text-transform: uppercase;
	li {
		margin-bottom: 4px;
	}
	a {
		transition: color .3s;
		&:hover {
			color: #fa4b67;
		}
	}
}

.my-calendar {
	width: 100%;
	max-width: 140px;
	font-size: 16px;
}

.my-calendar__heading {
	margin-bottom: 4px;
	font-size: 16px;
	color: #fa4b67;
	text-transform: uppercase;
}

.my-calendar__content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-right: -8px;
}

.my-calendar__item {
	margin-bottom: 4px;
	min-width: 12px;
	margin-right: 2px;
	transition: color .3s;
	&:hover, &.active {
		color: #fa4b67;
	}
}
