@media (max-width: 1440px) {

}

@media (max-width: 1199.98px) {
 	.card__section {
 		&:first-child {
 			width: 40%;
 			padding-right: 15px;
 		}
 		&:last-child {
 			width: 60%;
 			padding-left: 15px;
 		}
 	}

	.webinar-card__title {
		font-size: 3vw;
	}

	.webinar-card__duration {
		font-size: 3vw;
	}

	.seminar-label {
		width: 140px;
    height: 44px;
    line-height: 44px;
    font-size: 30px;
	}

	.seminar-heading {
		padding-left: 126px;
	}

	.seminar-item__data {
		margin-right: 20px;
    width: 100px;
    min-width: 100px;
    padding-right: 12px;
	}

	.seminar-item__date {
		line-height: 32px;
		font-size: 32px;
	}

	.seminar-item__time {
		font-size: 16px;
	}
}

@media (max-width: 991.98px) {
	.card__content {
		margin-right: 170px;
	}

	.nav-line {
		width: 170px;
		a {
	    height: 48px;
	    padding: 6px 10px;
	    font-size: 16px;
		}
	}

	.card-pager-item {
	    margin-right: 20px;
	    height: 170px;
	    // padding: 10px;
	    border: 2px solid transparent;
	}

	.card-pager__next {
	    width: 40px;
	    min-width: 40px;
	    height: 170px;
	    border: 2px solid transparent;
	}

	.webinar-card {
		flex-wrap: wrap;
	}

	.webinar-card__content {
		width: 65%;
		padding-right: 0;
		&:before {
			display: none;
		}
	}

	.webinar-card__top {
		height: auto;
	}

	.webinar-card__list {
		// margin-top: 40px;
		padding: 0;
	}

	.footer__inner {
		> div {
			max-width: 100%;
		}
	}
	.event__title {
		line-height: 50px;
    	font-size: 58px;
	}

	.seminar-block {

	}

	.seminar-items {
		column-count: 1;
	}

	.footer__social {
		display: flex;
		align-items: center;
		justify-content: center;
		> div {
			margin-left: 20px;
			margin-right: 20px;
		}
	}
}

@media (max-width: 767.98px) {
	.burger, .burger-popup, .overlay {
		display: block;
	}

	.header-nav {
		display: none;
	}

	.main__inner {
		flex-wrap: wrap;
		// flex-grow: 0;
	}

	.main__section {
		&:first-child {
			width: 100%;
			min-width: 0;
			padding: 0;
		}
		&:last-child {
			width: 100%;
			flex-grow: 1;
		}
	}

	.main__img {
		position: relative;
		top: auto;
		img {
			max-height: 300px;
		}
	}

	.main__bottom {
		margin-left: -15px;
	}

	.card__sections {
		display: block;
	}

	.card__section {
		&:first-child {
			align-items: flex-start;
			width: 100%;
			padding-right: 170px;
			margin-bottom: 30px;
		}
		&:last-child {
			width: 100%;
			padding: 0;
		}
	}

	.card__img {
		min-height: 240px;
	}

	.card__img-slide {
		max-height: 450px;
	}

	.card__content {
		max-width: 100%;
		min-height: 0;
		margin: 0;
	}

	.webinar-card__img {
		width: 100%;
	}

	.webinar-card__content {
		width: 100%;
		padding: 0;
	}

	.webinar-card__header {
		margin-right: -15px;
		padding-right: 45px;
	}

	.webinar-card__title {
		font-size: 30px;
	}

	.webinar-card__duration {
		font-size: 30px;
	}

	.event__title {
		line-height: 40px;
    font-size: 48px;
	}

}

@media (max-width: 575.98px) {
	.main__img {
		position: relative;
		top: auto;
		img {
			max-height: 200px;
		}
	}

	.card__section {
		&:first-child {
			padding-right: 120px;
		}
	}

	.card__img-slide {
		max-height: 350px;
	}

	.nav-line {
    width: 120px;
		a {
		  min-height: 40px;
		  padding: 4px 10px;
		  font-size: 13px;
		}
	}

	.card-pager-item {
    margin-right: 10px;
    height: 120px;
	}

	.card-pager__next {
    height: 120px;
	}

	.webinar-card__header {
		flex-wrap: wrap;
	}

	.event__title {
		line-height: 30px;
    font-size: 38px;
	}

	.seminar-block {
		padding: 0;
	}

	.seminar-label {
    position: relative;
    top: 0;
    left: 0;
    // width: 170px;
    // height: 50px;
    // line-height: 50px;
    // font-family: Bebas, sans-serif;
    // font-size: 36px;
    // color: #fff;
    // text-align: center;
    // text-transform: uppercase;
    // background: #a7272e;
	}

	.seminar-heading {
		padding-left: 0;
	}

	.seminar-item {
		display: block;
	}

	.seminar-item__data {
		margin-bottom: 10px;
		padding: 0;
		text-align: left;
		border: none;
	}

	.seminar-item__content {
		display: block;
	}

	.seminar-item__link {
		margin-top: 20px;
	}

	.footer__social {
		display: block;
		> div {
			margin-left: 0;
			margin-right: 0;
		}
	}
}
