.social-items {
	margin-right: -12px;
	.social-item {
		margin-right: 12px;
	}
}

.social-item {
	position: relative;
	vertical-align: middle;
	line-height: 1;
	font-size: 26px;
	color: #fff;
	transition: color .3s;
	[class^="icon-"], [class*=" icon-"] {
		margin: auto;
		&:before {
			width: auto;
  		margin-left: 0;
  		margin-right: 0;
  	}
	}
	&:hover {
		color: #fa4b67;
	}
}


.social-item-big {
	font-size: 34px;
}