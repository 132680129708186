body.overflow {
  overflow: hidden;
}

/*  burger
========================================== */
.burger {
  display: none;
  position: relative;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 22px;
  right: 5px;
  z-index: 16;
  border-radius: 0;
  background-color: transparent;
  border: none;
  transition: border-color .3s, background-color .3s;
}
.burger span {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -1px;
}
.burger span, .burger span::before, .burger span::after {
  display: block;
  width: 24px;
  height: 3px;
  background-color: #a8272e;
  outline: 1px solid transparent;
  transition-property: background-color, transform;
  transition-duration: .3s;
}
.burger span::before, .burger span::after {
  position: absolute;
  content: "";
}
.burger span::before {
  top: -7px;
}
.burger span::after {
  top: 7px;
}
.burger.clicked {
  border-color: transparent;
  background-color: transparent;
}
.burger.clicked span {
  background-color: transparent;
}
.burger.clicked span::before {
  transform: translateY(7px) rotate(45deg);
}
.burger.clicked span::after {
  transform: translateY(-7px) rotate(-45deg);
}
.burger.clicked span:before, .burger.clicked span:after {
  background-color: #ffffff;
}

.burger:hover {
  cursor: pointer;
}

/*  .burger-popup
========================================== */
.burger-popup {
  display: none;
  background-color: #a7282e;
  position: fixed;
  z-index: 14;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px 40px;
  color: #fff;
  font-size: 18px;
  transform: translateX(100%);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.burger-popup.show {
  transform: translateX(0px);
}

.burger-popup hr {
  margin-top: 24px;
  margin-bottom: 24px;
  opacity: .2
}

.burger-nav {
  list-style: none;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-size: 22px;
  li {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    padding-left: 20px;
    &:before {
      position: absolute;
      content: '';
      margin-top: 10px;
      margin-left: -20px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
    }
  }
  a:active, a.active {
    color: #fa4b67;
    &:before {
      background-color: currentColor;
    }
  }
}

/*  overlay
========================================== */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in-out;
  background-color: #1e1e1e;
  opacity: 0;
  visibility: hidden;
}
.overlay.show {
  opacity: .5;
  visibility: visible;
}
