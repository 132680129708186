.main-slider {
	position: relative;
	display: flex;
	min-height: 100vh;
	margin-top: -80px;
	padding-top: 80px;
}

.main {
	display: flex !important;
	flex-direction: column;
	width: 100%;
	// border: 2px solid red;
	overflow: hidden;
	// img {
	// 	 -moz-transform: scaleX(-1);
	//   -o-transform: scaleX(-1);
	// 	-webkit-transform: scaleX(-1);
	//   transform: scaleX(-1);
	//   filter: FlipH;
	//   -ms-filter: "FlipH";
	// }
}

.main_blue {
	.main__title {
		color: #0073ac;
	}
	.main__bottom {
		background-color: #c8e7f1;
		&:before {
			background-color: #c8e7f1;
		}
	}
}

.main_aqua {
	.main__title {
		color: #007e8a;
	}
	.main__bottom {
		background-color: #c2f7f7;
		&:before {
			background-color: #c2f7f7;
		}
	}
}

.main_green {
	.main__title {
		color: #479434;
	}
	.main__bottom {
		background-color: #c8f1d5;
		&:before {
			background-color: #c8f1d5;
		}
	}
}

.main_red {
	.main__title {
		color: #a8272e;
	}
	.main__bottom {
		background-color: #f1cbc8;
		&:before {
			background-color: #f1cbc8;
		}
	}	
}

.main_pink {
	.main__title {
		color: #e80071;
	}
	.main__bottom {
		background-color: #f4d5e1;
		&:before {
			background-color: #f4d5e1;
		}
	}	
}

.main__inner {
	position: relative;
	flex-grow: 1;
	display: flex;
	padding-top: 40px;
	padding-bottom: 40px;
	z-index: 2;
}

.main__section {
	position: relative;
	display: flex;
	flex-direction: column;
	// align-items: flex-end;
	justify-content: flex-end;
	// border: 2px solid green;
	// > div {
		// max-height: 100%;
		// border: 2px solid orange;
	// }
	&:first-child {
		width: 400px;
		min-width: 400px;
		padding-right: 20px;
	}
	&:last-child {
		flex-grow: 1;
	}
}

.main__content {
	min-height: 50%;
	padding-bottom: 10px;
	// border: 1px solid red;
}

.main__img {
	position: absolute;
	display: flex;
	top: 0;
	bottom: -40px;
	width: 100%;
	// height: 100%;
	// top: 0;
	img {
		// max-width: 100%;
		// position: relative;
		max-height: 100%;
		margin: auto auto 0;
		// overflow: hidden;
		// margin-left: auto;
		// margin-right: auto;
	}
}

.main__img-inner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.main__img-shadow {
	position: absolute;
	top: 100%;
	left: 50%;
	width: 100%;
	height: 110px;
	background-color: red;
	background: center top no-repeat;
	background-size: 100% auto;
	transform: translateX(-50%);
}

.main__title {
	margin-bottom: 20px;
	font-size: 40px;
	// color: #1b8abd;
	text-transform: uppercase;
  span {
  	background: linear-gradient(to right, currentColor, currentColor 100%);
  	background-position: 0 100%;
  	background-size: 100% 3px;
  	background-repeat: repeat-x;
  }
}

.main__text {
	// line-height: 
	font-size: 25px;
	color: #848989;

}

.main__bottom {
	position: relative;
	display: flex;
	height: 110px;
	padding-left: 400px;
	// > div {
		// position: relative;
	background-color: #c8e7f1;
	&:before {
		position: absolute;
		left: 100%;
		content: '';
		width: 100%;
		height: 100%;
		background-color: #c8e7f1;
	}
	// }
}

.main__bottom-inner {
	width: 100%;
	background: center top no-repeat;
	background-size: contain;
}

.main-pager {
	position: absolute;
	display: flex;
	align-items: center;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 110px;
	.slick-dots {
		position: relative;
		bottom: 0;
		text-align: left;
	}
}

.main-pager__inner {
	padding: 19px;
}

// .main__bottom-section {
// 	border: 2px solid green;
// 	height: 100%;
// 	&:first-child {
// 		width: 400px;
// 		min-width: 400px;
// 	}
// 	&:last-child {
// 		flex-grow: 1;
// 		// background-size: cover;
// 		// bac
// 		background-position: left bottom;
// 		transform: scale(1, -1);
// 	}
// }

