#page {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	min-width: 320px;
	// max-width: 1920px;
	min-height: 100vh;
	overflow: hidden;
	// background-color: #f8f8f8;
	box-shadow: 0 0 4px rgba(0, 0, 0, .2);
}

#page-content {
	position: relative;
	flex-grow: 1;
	// display: flex;
	// flex-direction: column;
}

.container-fluid {
	max-width: 1200px;
}


.logo,
.logo-big {
  display: inline-block;
  width: 100%;
  // width: 132px;
  width: 140px;
  img {
    width: 100%;
  }
}

.logo-big {
	width: 330px;
}

.h-quote {
	&:after {
		display: block;
		content: '';
		margin: 12px auto 30px;
		width: 46px;
		height: 35px;
		background: url(../img/quote.png) center no-repeat;
		background-size: contain;
	}
}

.text {
	font-size: 22px;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
}

.bg {
	background: center no-repeat;
	background-size: cover;
}

.bg-white {
	background-color: #fff;
}

.block-shadow {
	padding: 30px 40px;
	background-color: #fff;
	box-shadow: 0 0 18px rgba(0, 0, 0, .11);
}

.block-border {
	padding: 30px;
	border-radius: 4px;
	border: 1px solid #e5e5e5;
}

.block-sections {
	display: flex;
	background-color: #fff;
	box-shadow: 0 0 18px rgba(0, 0, 0, .11);
}

.block-sections__section {
	width: 50%;
	padding: 40px;
	&:last-child {
		border-left: 1px solid #e6e5e5;
	}
}

.block-left-border {
	position: relative;
	margin-bottom: 30px;
	padding-left: 30px;
	&:last-child {
		margin-bottom: 0;
	}
	&:before {
		position: absolute;
		content: '';
		top: 4px;
		left: 0;
		bottom: 4px;
		width: 6px;
		background-color: #1876e1;
	}
}

.h-bebas {
	font-family: Bebas, sans-serif;
}

.typography {
	// line-height: 28px;
	// font-size: 16px;
	// line-height: 15px;
	// font-size: 12px;

	p, h1, h2, h3, h4, h5, h6, ul, ol {
		margin-bottom: 14px;
	  	&:last-child {
	  		margin-bottom: 0;
	  	}
	}

	// p, ul, ol {
	// 	margin-bottom: 30px;
	// }

	h1 {
		// line-height: 55px;
		font-size: 45px;
	}

	h2 {
		// line-height: 45px;
  		font-size: 30px;
	}

	h3 {
		// line-height: 40px;
		font-size: 25px;
	}

	h4 {
		// line-height: 34px;
		font-size: 20px;
	}

	h5 {
		// line-height: 30px;
		font-size: 18px;
	}

	h6 {
		// line-height: 26px;
		font-size: 16px;
	}

	strong {
		color: #a8272e;
		font-weight: inherit;
	}

	img {
		margin-top: 28px;
		margin-bottom: 28px;
		max-width: 100%;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	ol {
		padding-left: 20px;
	}

	ul {
		list-style: none;
		padding-left: 34px;
		li {
			// margin-bottom: 10px;
		  	&:last-child {
		  		margin-bottom: 0;
		  	}
			&:before {
				position: absolute;
				content: '';
				margin-top: 10px;
				margin-left: -34px;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: #1876e1;
			}
		}
	}

	blockquote {
		position: relative;
		margin-top: 30px;
		margin-bottom: 30px;
		max-width: 580px;
		padding-left: 26px;
		line-height: 28px;
		font-size: 20px;
		&:before {
			position: absolute;
			top: 6px;
			left: 0;
			bottom: 8px;
			content: '';
			width: 6px;
			background-color: #1876e1;
		}
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.fs-14 {
	line-height: 14px !important;
	font-size: 14px;
}

.fs-12 {
	// line-height: 14px;
	font-size: 12px !important;
}

.list-true,
.list-false {
	li {
		&:before {
			position: absolute;
			font-family: "fontello";
			margin-top: 4px !important;
			margin-left: -34px;
			width: 20px !important;
			height: 20px !important;
			line-height: 20px;
			border-radius: 50%;
			background-color: transparent !important;
		}
	}
}

.list-true {
	li {
		&:before {
			content: '\e805' !important;
			color: #5ac862;
		}
	}
}

.list-false {
	li {
		&:before {
			content: '\e80d' !important;
			color: #ea2f2f;
		}
	}
}

.page-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 45px;
	height: 90px;
	padding: 20px 30px;
	box-shadow: 0 0 18px rgba(0, 0, 0, .11);
}

._js_filter-toggle-item {
	display: none;
}
