.event-slider {
	position: relative;
	display: flex;
	min-height: 100vh;
	&:first-child {
		margin-top: -80px;
		padding-top: 80px;
	}
}

.event {
	display: flex !important;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	min-height: 400px;
	padding: 40px 0 70px;
	color: #fff;
	text-align: center;
	background: center center no-repeat;
	background-size: cover;
}

.event__title {
	line-height: 60px;
	font-family: Bebas, sans-serif;
	font-size: 68px;
}

.event__text {
	margin-bottom: 30px;
	font-size: 22px;
}

.event-pager {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 50px;
	z-index: 2;
	.slick-dots {
		position: static;
	}
}