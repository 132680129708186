/* font-weight: 100          - thin
 * font-weight: 200          - ultralight
 * font-weight: 300          - light
 * font-weight: 400 (normal) - regular
 * font-weight: 500          - medium
 * font-weight: 600          - semibold
 * font-weight: 700 (bold)   - bold
 * font-weight: 800          - extrabold
 * font-weight: 900          - black
 */

// Заголовки
@font-face {
  font-family: DINPro;
  src: url('../font/DINPro-Bold.otf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: DINPro;
  src: url('../font/DINPro-Regular.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: DINPro;
  src: url('../font/DINPro-Light.otf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Bebas;
  src: url('../font/BebasNeue.otf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
