@charset "UTF-8";
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #4c4c4c !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #333333 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #4c4c4c !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 4px !important; }

.mt-1,
.my-1 {
  margin-top: 4px !important; }

.mr-1,
.mx-1 {
  margin-right: 4px !important; }

.mb-1,
.my-1 {
  margin-bottom: 4px !important; }

.ml-1,
.mx-1 {
  margin-left: 4px !important; }

.m-2 {
  margin: 8px !important; }

.mt-2,
.my-2 {
  margin-top: 8px !important; }

.mr-2,
.mx-2 {
  margin-right: 8px !important; }

.mb-2,
.my-2 {
  margin-bottom: 8px !important; }

.ml-2,
.mx-2 {
  margin-left: 8px !important; }

.m-3 {
  margin: 12px !important; }

.mt-3,
.my-3 {
  margin-top: 12px !important; }

.mr-3,
.mx-3 {
  margin-right: 12px !important; }

.mb-3,
.my-3 {
  margin-bottom: 12px !important; }

.ml-3,
.mx-3 {
  margin-left: 12px !important; }

.m-4 {
  margin: 16px !important; }

.mt-4,
.my-4 {
  margin-top: 16px !important; }

.mr-4,
.mx-4 {
  margin-right: 16px !important; }

.mb-4,
.my-4 {
  margin-bottom: 16px !important; }

.ml-4,
.mx-4 {
  margin-left: 16px !important; }

.m-5 {
  margin: 20px !important; }

.mt-5,
.my-5 {
  margin-top: 20px !important; }

.mr-5,
.mx-5 {
  margin-right: 20px !important; }

.mb-5,
.my-5 {
  margin-bottom: 20px !important; }

.ml-5,
.mx-5 {
  margin-left: 20px !important; }

.m-6 {
  margin: 30px !important; }

.mt-6,
.my-6 {
  margin-top: 30px !important; }

.mr-6,
.mx-6 {
  margin-right: 30px !important; }

.mb-6,
.my-6 {
  margin-bottom: 30px !important; }

.ml-6,
.mx-6 {
  margin-left: 30px !important; }

.m-7 {
  margin: 40px !important; }

.mt-7,
.my-7 {
  margin-top: 40px !important; }

.mr-7,
.mx-7 {
  margin-right: 40px !important; }

.mb-7,
.my-7 {
  margin-bottom: 40px !important; }

.ml-7,
.mx-7 {
  margin-left: 40px !important; }

.m-8 {
  margin: 50px !important; }

.mt-8,
.my-8 {
  margin-top: 50px !important; }

.mr-8,
.mx-8 {
  margin-right: 50px !important; }

.mb-8,
.my-8 {
  margin-bottom: 50px !important; }

.ml-8,
.mx-8 {
  margin-left: 50px !important; }

.m-9 {
  margin: 60px !important; }

.mt-9,
.my-9 {
  margin-top: 60px !important; }

.mr-9,
.mx-9 {
  margin-right: 60px !important; }

.mb-9,
.my-9 {
  margin-bottom: 60px !important; }

.ml-9,
.mx-9 {
  margin-left: 60px !important; }

.m-10 {
  margin: 70px !important; }

.mt-10,
.my-10 {
  margin-top: 70px !important; }

.mr-10,
.mx-10 {
  margin-right: 70px !important; }

.mb-10,
.my-10 {
  margin-bottom: 70px !important; }

.ml-10,
.mx-10 {
  margin-left: 70px !important; }

.m-11 {
  margin: 80px !important; }

.mt-11,
.my-11 {
  margin-top: 80px !important; }

.mr-11,
.mx-11 {
  margin-right: 80px !important; }

.mb-11,
.my-11 {
  margin-bottom: 80px !important; }

.ml-11,
.mx-11 {
  margin-left: 80px !important; }

.m-12 {
  margin: 90px !important; }

.mt-12,
.my-12 {
  margin-top: 90px !important; }

.mr-12,
.mx-12 {
  margin-right: 90px !important; }

.mb-12,
.my-12 {
  margin-bottom: 90px !important; }

.ml-12,
.mx-12 {
  margin-left: 90px !important; }

.m-13 {
  margin: 100px !important; }

.mt-13,
.my-13 {
  margin-top: 100px !important; }

.mr-13,
.mx-13 {
  margin-right: 100px !important; }

.mb-13,
.my-13 {
  margin-bottom: 100px !important; }

.ml-13,
.mx-13 {
  margin-left: 100px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 4px !important; }

.pt-1,
.py-1 {
  padding-top: 4px !important; }

.pr-1,
.px-1 {
  padding-right: 4px !important; }

.pb-1,
.py-1 {
  padding-bottom: 4px !important; }

.pl-1,
.px-1 {
  padding-left: 4px !important; }

.p-2 {
  padding: 8px !important; }

.pt-2,
.py-2 {
  padding-top: 8px !important; }

.pr-2,
.px-2 {
  padding-right: 8px !important; }

.pb-2,
.py-2 {
  padding-bottom: 8px !important; }

.pl-2,
.px-2 {
  padding-left: 8px !important; }

.p-3 {
  padding: 12px !important; }

.pt-3,
.py-3 {
  padding-top: 12px !important; }

.pr-3,
.px-3 {
  padding-right: 12px !important; }

.pb-3,
.py-3 {
  padding-bottom: 12px !important; }

.pl-3,
.px-3 {
  padding-left: 12px !important; }

.p-4 {
  padding: 16px !important; }

.pt-4,
.py-4 {
  padding-top: 16px !important; }

.pr-4,
.px-4 {
  padding-right: 16px !important; }

.pb-4,
.py-4 {
  padding-bottom: 16px !important; }

.pl-4,
.px-4 {
  padding-left: 16px !important; }

.p-5 {
  padding: 20px !important; }

.pt-5,
.py-5 {
  padding-top: 20px !important; }

.pr-5,
.px-5 {
  padding-right: 20px !important; }

.pb-5,
.py-5 {
  padding-bottom: 20px !important; }

.pl-5,
.px-5 {
  padding-left: 20px !important; }

.p-6 {
  padding: 30px !important; }

.pt-6,
.py-6 {
  padding-top: 30px !important; }

.pr-6,
.px-6 {
  padding-right: 30px !important; }

.pb-6,
.py-6 {
  padding-bottom: 30px !important; }

.pl-6,
.px-6 {
  padding-left: 30px !important; }

.p-7 {
  padding: 40px !important; }

.pt-7,
.py-7 {
  padding-top: 40px !important; }

.pr-7,
.px-7 {
  padding-right: 40px !important; }

.pb-7,
.py-7 {
  padding-bottom: 40px !important; }

.pl-7,
.px-7 {
  padding-left: 40px !important; }

.p-8 {
  padding: 50px !important; }

.pt-8,
.py-8 {
  padding-top: 50px !important; }

.pr-8,
.px-8 {
  padding-right: 50px !important; }

.pb-8,
.py-8 {
  padding-bottom: 50px !important; }

.pl-8,
.px-8 {
  padding-left: 50px !important; }

.p-9 {
  padding: 60px !important; }

.pt-9,
.py-9 {
  padding-top: 60px !important; }

.pr-9,
.px-9 {
  padding-right: 60px !important; }

.pb-9,
.py-9 {
  padding-bottom: 60px !important; }

.pl-9,
.px-9 {
  padding-left: 60px !important; }

.p-10 {
  padding: 70px !important; }

.pt-10,
.py-10 {
  padding-top: 70px !important; }

.pr-10,
.px-10 {
  padding-right: 70px !important; }

.pb-10,
.py-10 {
  padding-bottom: 70px !important; }

.pl-10,
.px-10 {
  padding-left: 70px !important; }

.p-11 {
  padding: 80px !important; }

.pt-11,
.py-11 {
  padding-top: 80px !important; }

.pr-11,
.px-11 {
  padding-right: 80px !important; }

.pb-11,
.py-11 {
  padding-bottom: 80px !important; }

.pl-11,
.px-11 {
  padding-left: 80px !important; }

.p-12 {
  padding: 90px !important; }

.pt-12,
.py-12 {
  padding-top: 90px !important; }

.pr-12,
.px-12 {
  padding-right: 90px !important; }

.pb-12,
.py-12 {
  padding-bottom: 90px !important; }

.pl-12,
.px-12 {
  padding-left: 90px !important; }

.p-13 {
  padding: 100px !important; }

.pt-13,
.py-13 {
  padding-top: 100px !important; }

.pr-13,
.px-13 {
  padding-right: 100px !important; }

.pb-13,
.py-13 {
  padding-bottom: 100px !important; }

.pl-13,
.px-13 {
  padding-left: 100px !important; }

.m-n1 {
  margin: -4px !important; }

.mt-n1,
.my-n1 {
  margin-top: -4px !important; }

.mr-n1,
.mx-n1 {
  margin-right: -4px !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -4px !important; }

.ml-n1,
.mx-n1 {
  margin-left: -4px !important; }

.m-n2 {
  margin: -8px !important; }

.mt-n2,
.my-n2 {
  margin-top: -8px !important; }

.mr-n2,
.mx-n2 {
  margin-right: -8px !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -8px !important; }

.ml-n2,
.mx-n2 {
  margin-left: -8px !important; }

.m-n3 {
  margin: -12px !important; }

.mt-n3,
.my-n3 {
  margin-top: -12px !important; }

.mr-n3,
.mx-n3 {
  margin-right: -12px !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -12px !important; }

.ml-n3,
.mx-n3 {
  margin-left: -12px !important; }

.m-n4 {
  margin: -16px !important; }

.mt-n4,
.my-n4 {
  margin-top: -16px !important; }

.mr-n4,
.mx-n4 {
  margin-right: -16px !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -16px !important; }

.ml-n4,
.mx-n4 {
  margin-left: -16px !important; }

.m-n5 {
  margin: -20px !important; }

.mt-n5,
.my-n5 {
  margin-top: -20px !important; }

.mr-n5,
.mx-n5 {
  margin-right: -20px !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -20px !important; }

.ml-n5,
.mx-n5 {
  margin-left: -20px !important; }

.m-n6 {
  margin: -30px !important; }

.mt-n6,
.my-n6 {
  margin-top: -30px !important; }

.mr-n6,
.mx-n6 {
  margin-right: -30px !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -30px !important; }

.ml-n6,
.mx-n6 {
  margin-left: -30px !important; }

.m-n7 {
  margin: -40px !important; }

.mt-n7,
.my-n7 {
  margin-top: -40px !important; }

.mr-n7,
.mx-n7 {
  margin-right: -40px !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -40px !important; }

.ml-n7,
.mx-n7 {
  margin-left: -40px !important; }

.m-n8 {
  margin: -50px !important; }

.mt-n8,
.my-n8 {
  margin-top: -50px !important; }

.mr-n8,
.mx-n8 {
  margin-right: -50px !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -50px !important; }

.ml-n8,
.mx-n8 {
  margin-left: -50px !important; }

.m-n9 {
  margin: -60px !important; }

.mt-n9,
.my-n9 {
  margin-top: -60px !important; }

.mr-n9,
.mx-n9 {
  margin-right: -60px !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -60px !important; }

.ml-n9,
.mx-n9 {
  margin-left: -60px !important; }

.m-n10 {
  margin: -70px !important; }

.mt-n10,
.my-n10 {
  margin-top: -70px !important; }

.mr-n10,
.mx-n10 {
  margin-right: -70px !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -70px !important; }

.ml-n10,
.mx-n10 {
  margin-left: -70px !important; }

.m-n11 {
  margin: -80px !important; }

.mt-n11,
.my-n11 {
  margin-top: -80px !important; }

.mr-n11,
.mx-n11 {
  margin-right: -80px !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -80px !important; }

.ml-n11,
.mx-n11 {
  margin-left: -80px !important; }

.m-n12 {
  margin: -90px !important; }

.mt-n12,
.my-n12 {
  margin-top: -90px !important; }

.mr-n12,
.mx-n12 {
  margin-right: -90px !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -90px !important; }

.ml-n12,
.mx-n12 {
  margin-left: -90px !important; }

.m-n13 {
  margin: -100px !important; }

.mt-n13,
.my-n13 {
  margin-top: -100px !important; }

.mr-n13,
.mx-n13 {
  margin-right: -100px !important; }

.mb-n13,
.my-n13 {
  margin-bottom: -100px !important; }

.ml-n13,
.mx-n13 {
  margin-left: -100px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 4px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 4px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 4px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 4px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 4px !important; }
  .m-sm-2 {
    margin: 8px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 8px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 8px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 8px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 8px !important; }
  .m-sm-3 {
    margin: 12px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 12px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 12px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 12px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 12px !important; }
  .m-sm-4 {
    margin: 16px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 16px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 16px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 16px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 16px !important; }
  .m-sm-5 {
    margin: 20px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 20px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 20px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 20px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 20px !important; }
  .m-sm-6 {
    margin: 30px !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 30px !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 30px !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 30px !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 30px !important; }
  .m-sm-7 {
    margin: 40px !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 40px !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 40px !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 40px !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 40px !important; }
  .m-sm-8 {
    margin: 50px !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 50px !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 50px !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 50px !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 50px !important; }
  .m-sm-9 {
    margin: 60px !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 60px !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 60px !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 60px !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 60px !important; }
  .m-sm-10 {
    margin: 70px !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 70px !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 70px !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 70px !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 70px !important; }
  .m-sm-11 {
    margin: 80px !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 80px !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 80px !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 80px !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 80px !important; }
  .m-sm-12 {
    margin: 90px !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 90px !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 90px !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 90px !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 90px !important; }
  .m-sm-13 {
    margin: 100px !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 100px !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 100px !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 100px !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 100px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 4px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 4px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 4px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 4px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 4px !important; }
  .p-sm-2 {
    padding: 8px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 8px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 8px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 8px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 8px !important; }
  .p-sm-3 {
    padding: 12px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 12px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 12px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 12px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 12px !important; }
  .p-sm-4 {
    padding: 16px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 16px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 16px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 16px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 16px !important; }
  .p-sm-5 {
    padding: 20px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 20px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 20px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 20px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 20px !important; }
  .p-sm-6 {
    padding: 30px !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 30px !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 30px !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 30px !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 30px !important; }
  .p-sm-7 {
    padding: 40px !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 40px !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 40px !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 40px !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 40px !important; }
  .p-sm-8 {
    padding: 50px !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 50px !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 50px !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 50px !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 50px !important; }
  .p-sm-9 {
    padding: 60px !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 60px !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 60px !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 60px !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 60px !important; }
  .p-sm-10 {
    padding: 70px !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 70px !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 70px !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 70px !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 70px !important; }
  .p-sm-11 {
    padding: 80px !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 80px !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 80px !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 80px !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 80px !important; }
  .p-sm-12 {
    padding: 90px !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 90px !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 90px !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 90px !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 90px !important; }
  .p-sm-13 {
    padding: 100px !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 100px !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 100px !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 100px !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 100px !important; }
  .m-sm-n1 {
    margin: -4px !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -4px !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -4px !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -4px !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -4px !important; }
  .m-sm-n2 {
    margin: -8px !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -8px !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -8px !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -8px !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -8px !important; }
  .m-sm-n3 {
    margin: -12px !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -12px !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -12px !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -12px !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -12px !important; }
  .m-sm-n4 {
    margin: -16px !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -16px !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -16px !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -16px !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -16px !important; }
  .m-sm-n5 {
    margin: -20px !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -20px !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -20px !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -20px !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -20px !important; }
  .m-sm-n6 {
    margin: -30px !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -30px !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -30px !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -30px !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -30px !important; }
  .m-sm-n7 {
    margin: -40px !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -40px !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -40px !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -40px !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -40px !important; }
  .m-sm-n8 {
    margin: -50px !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -50px !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -50px !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -50px !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -50px !important; }
  .m-sm-n9 {
    margin: -60px !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -60px !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -60px !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -60px !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -60px !important; }
  .m-sm-n10 {
    margin: -70px !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -70px !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -70px !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -70px !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -70px !important; }
  .m-sm-n11 {
    margin: -80px !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -80px !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -80px !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -80px !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -80px !important; }
  .m-sm-n12 {
    margin: -90px !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -90px !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -90px !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -90px !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -90px !important; }
  .m-sm-n13 {
    margin: -100px !important; }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -100px !important; }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -100px !important; }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -100px !important; }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -100px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 4px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 4px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 4px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 4px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 4px !important; }
  .m-md-2 {
    margin: 8px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 8px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 8px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 8px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 8px !important; }
  .m-md-3 {
    margin: 12px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 12px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 12px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 12px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 12px !important; }
  .m-md-4 {
    margin: 16px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 16px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 16px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 16px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 16px !important; }
  .m-md-5 {
    margin: 20px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 20px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 20px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 20px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 20px !important; }
  .m-md-6 {
    margin: 30px !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 30px !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 30px !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 30px !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 30px !important; }
  .m-md-7 {
    margin: 40px !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 40px !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 40px !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 40px !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 40px !important; }
  .m-md-8 {
    margin: 50px !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 50px !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 50px !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 50px !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 50px !important; }
  .m-md-9 {
    margin: 60px !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 60px !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 60px !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 60px !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 60px !important; }
  .m-md-10 {
    margin: 70px !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 70px !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 70px !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 70px !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 70px !important; }
  .m-md-11 {
    margin: 80px !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 80px !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 80px !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 80px !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 80px !important; }
  .m-md-12 {
    margin: 90px !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 90px !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 90px !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 90px !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 90px !important; }
  .m-md-13 {
    margin: 100px !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 100px !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 100px !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 100px !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 100px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 4px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 4px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 4px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 4px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 4px !important; }
  .p-md-2 {
    padding: 8px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 8px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 8px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 8px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 8px !important; }
  .p-md-3 {
    padding: 12px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 12px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 12px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 12px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 12px !important; }
  .p-md-4 {
    padding: 16px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 16px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 16px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 16px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 16px !important; }
  .p-md-5 {
    padding: 20px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 20px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 20px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 20px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 20px !important; }
  .p-md-6 {
    padding: 30px !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 30px !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 30px !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 30px !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 30px !important; }
  .p-md-7 {
    padding: 40px !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 40px !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 40px !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 40px !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 40px !important; }
  .p-md-8 {
    padding: 50px !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 50px !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 50px !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 50px !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 50px !important; }
  .p-md-9 {
    padding: 60px !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 60px !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 60px !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 60px !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 60px !important; }
  .p-md-10 {
    padding: 70px !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 70px !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 70px !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 70px !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 70px !important; }
  .p-md-11 {
    padding: 80px !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 80px !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 80px !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 80px !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 80px !important; }
  .p-md-12 {
    padding: 90px !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 90px !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 90px !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 90px !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 90px !important; }
  .p-md-13 {
    padding: 100px !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 100px !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 100px !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 100px !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 100px !important; }
  .m-md-n1 {
    margin: -4px !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -4px !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -4px !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -4px !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -4px !important; }
  .m-md-n2 {
    margin: -8px !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -8px !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -8px !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -8px !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -8px !important; }
  .m-md-n3 {
    margin: -12px !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -12px !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -12px !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -12px !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -12px !important; }
  .m-md-n4 {
    margin: -16px !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -16px !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -16px !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -16px !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -16px !important; }
  .m-md-n5 {
    margin: -20px !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -20px !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -20px !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -20px !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -20px !important; }
  .m-md-n6 {
    margin: -30px !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -30px !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -30px !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -30px !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -30px !important; }
  .m-md-n7 {
    margin: -40px !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -40px !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -40px !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -40px !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -40px !important; }
  .m-md-n8 {
    margin: -50px !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -50px !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -50px !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -50px !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -50px !important; }
  .m-md-n9 {
    margin: -60px !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -60px !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -60px !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -60px !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -60px !important; }
  .m-md-n10 {
    margin: -70px !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -70px !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -70px !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -70px !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -70px !important; }
  .m-md-n11 {
    margin: -80px !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -80px !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -80px !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -80px !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -80px !important; }
  .m-md-n12 {
    margin: -90px !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -90px !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -90px !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -90px !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -90px !important; }
  .m-md-n13 {
    margin: -100px !important; }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -100px !important; }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -100px !important; }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -100px !important; }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -100px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 4px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 4px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 4px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 4px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 4px !important; }
  .m-lg-2 {
    margin: 8px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 8px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 8px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 8px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 8px !important; }
  .m-lg-3 {
    margin: 12px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 12px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 12px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 12px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 12px !important; }
  .m-lg-4 {
    margin: 16px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 16px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 16px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 16px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 16px !important; }
  .m-lg-5 {
    margin: 20px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 20px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 20px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 20px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 20px !important; }
  .m-lg-6 {
    margin: 30px !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 30px !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 30px !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 30px !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 30px !important; }
  .m-lg-7 {
    margin: 40px !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 40px !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 40px !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 40px !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 40px !important; }
  .m-lg-8 {
    margin: 50px !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 50px !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 50px !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 50px !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 50px !important; }
  .m-lg-9 {
    margin: 60px !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 60px !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 60px !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 60px !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 60px !important; }
  .m-lg-10 {
    margin: 70px !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 70px !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 70px !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 70px !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 70px !important; }
  .m-lg-11 {
    margin: 80px !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 80px !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 80px !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 80px !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 80px !important; }
  .m-lg-12 {
    margin: 90px !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 90px !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 90px !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 90px !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 90px !important; }
  .m-lg-13 {
    margin: 100px !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 100px !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 100px !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 100px !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 100px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 4px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 4px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 4px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 4px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 4px !important; }
  .p-lg-2 {
    padding: 8px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 8px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 8px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 8px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 8px !important; }
  .p-lg-3 {
    padding: 12px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 12px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 12px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 12px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 12px !important; }
  .p-lg-4 {
    padding: 16px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 16px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 16px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 16px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 16px !important; }
  .p-lg-5 {
    padding: 20px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 20px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 20px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 20px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 20px !important; }
  .p-lg-6 {
    padding: 30px !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 30px !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 30px !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 30px !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 30px !important; }
  .p-lg-7 {
    padding: 40px !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 40px !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 40px !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 40px !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 40px !important; }
  .p-lg-8 {
    padding: 50px !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 50px !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 50px !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 50px !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 50px !important; }
  .p-lg-9 {
    padding: 60px !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 60px !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 60px !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 60px !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 60px !important; }
  .p-lg-10 {
    padding: 70px !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 70px !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 70px !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 70px !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 70px !important; }
  .p-lg-11 {
    padding: 80px !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 80px !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 80px !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 80px !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 80px !important; }
  .p-lg-12 {
    padding: 90px !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 90px !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 90px !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 90px !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 90px !important; }
  .p-lg-13 {
    padding: 100px !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 100px !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 100px !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 100px !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 100px !important; }
  .m-lg-n1 {
    margin: -4px !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -4px !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -4px !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -4px !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -4px !important; }
  .m-lg-n2 {
    margin: -8px !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -8px !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -8px !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -8px !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -8px !important; }
  .m-lg-n3 {
    margin: -12px !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -12px !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -12px !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -12px !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -12px !important; }
  .m-lg-n4 {
    margin: -16px !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -16px !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -16px !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -16px !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -16px !important; }
  .m-lg-n5 {
    margin: -20px !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -20px !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -20px !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -20px !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -20px !important; }
  .m-lg-n6 {
    margin: -30px !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -30px !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -30px !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -30px !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -30px !important; }
  .m-lg-n7 {
    margin: -40px !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -40px !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -40px !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -40px !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -40px !important; }
  .m-lg-n8 {
    margin: -50px !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -50px !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -50px !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -50px !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -50px !important; }
  .m-lg-n9 {
    margin: -60px !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -60px !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -60px !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -60px !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -60px !important; }
  .m-lg-n10 {
    margin: -70px !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -70px !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -70px !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -70px !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -70px !important; }
  .m-lg-n11 {
    margin: -80px !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -80px !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -80px !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -80px !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -80px !important; }
  .m-lg-n12 {
    margin: -90px !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -90px !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -90px !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -90px !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -90px !important; }
  .m-lg-n13 {
    margin: -100px !important; }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -100px !important; }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -100px !important; }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -100px !important; }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -100px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 4px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 4px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 4px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 4px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 4px !important; }
  .m-xl-2 {
    margin: 8px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 8px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 8px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 8px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 8px !important; }
  .m-xl-3 {
    margin: 12px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 12px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 12px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 12px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 12px !important; }
  .m-xl-4 {
    margin: 16px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 16px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 16px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 16px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 16px !important; }
  .m-xl-5 {
    margin: 20px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 20px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 20px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 20px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 20px !important; }
  .m-xl-6 {
    margin: 30px !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 30px !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 30px !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 30px !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 30px !important; }
  .m-xl-7 {
    margin: 40px !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 40px !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 40px !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 40px !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 40px !important; }
  .m-xl-8 {
    margin: 50px !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 50px !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 50px !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 50px !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 50px !important; }
  .m-xl-9 {
    margin: 60px !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 60px !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 60px !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 60px !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 60px !important; }
  .m-xl-10 {
    margin: 70px !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 70px !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 70px !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 70px !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 70px !important; }
  .m-xl-11 {
    margin: 80px !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 80px !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 80px !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 80px !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 80px !important; }
  .m-xl-12 {
    margin: 90px !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 90px !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 90px !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 90px !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 90px !important; }
  .m-xl-13 {
    margin: 100px !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 100px !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 100px !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 100px !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 100px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 4px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 4px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 4px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 4px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 4px !important; }
  .p-xl-2 {
    padding: 8px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 8px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 8px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 8px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 8px !important; }
  .p-xl-3 {
    padding: 12px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 12px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 12px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 12px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 12px !important; }
  .p-xl-4 {
    padding: 16px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 16px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 16px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 16px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 16px !important; }
  .p-xl-5 {
    padding: 20px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 20px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 20px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 20px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 20px !important; }
  .p-xl-6 {
    padding: 30px !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 30px !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 30px !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 30px !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 30px !important; }
  .p-xl-7 {
    padding: 40px !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 40px !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 40px !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 40px !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 40px !important; }
  .p-xl-8 {
    padding: 50px !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 50px !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 50px !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 50px !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 50px !important; }
  .p-xl-9 {
    padding: 60px !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 60px !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 60px !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 60px !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 60px !important; }
  .p-xl-10 {
    padding: 70px !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 70px !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 70px !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 70px !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 70px !important; }
  .p-xl-11 {
    padding: 80px !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 80px !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 80px !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 80px !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 80px !important; }
  .p-xl-12 {
    padding: 90px !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 90px !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 90px !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 90px !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 90px !important; }
  .p-xl-13 {
    padding: 100px !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 100px !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 100px !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 100px !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 100px !important; }
  .m-xl-n1 {
    margin: -4px !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -4px !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -4px !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -4px !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -4px !important; }
  .m-xl-n2 {
    margin: -8px !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -8px !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -8px !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -8px !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -8px !important; }
  .m-xl-n3 {
    margin: -12px !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -12px !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -12px !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -12px !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -12px !important; }
  .m-xl-n4 {
    margin: -16px !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -16px !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -16px !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -16px !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -16px !important; }
  .m-xl-n5 {
    margin: -20px !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -20px !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -20px !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -20px !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -20px !important; }
  .m-xl-n6 {
    margin: -30px !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -30px !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -30px !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -30px !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -30px !important; }
  .m-xl-n7 {
    margin: -40px !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -40px !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -40px !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -40px !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -40px !important; }
  .m-xl-n8 {
    margin: -50px !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -50px !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -50px !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -50px !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -50px !important; }
  .m-xl-n9 {
    margin: -60px !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -60px !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -60px !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -60px !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -60px !important; }
  .m-xl-n10 {
    margin: -70px !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -70px !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -70px !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -70px !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -70px !important; }
  .m-xl-n11 {
    margin: -80px !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -80px !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -80px !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -80px !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -80px !important; }
  .m-xl-n12 {
    margin: -90px !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -90px !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -90px !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -90px !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -90px !important; }
  .m-xl-n13 {
    margin: -100px !important; }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -100px !important; }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -100px !important; }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -100px !important; }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -100px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #4c4c4c !important; }

a.text-light:hover, a.text-light:focus {
  color: #262626 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: DINPro, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 20px; }

p {
  margin-top: 0;
  margin-bottom: 20px; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 8px; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 20px;
  font-weight: normal;
  line-height: 1.2;
  color: #a8272e; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 4px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 2px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  height: 48px;
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: normal;
  line-height: 1.5;
  color: #24232d;
  background-color: #f3f4f4;
  background-clip: padding-box;
  border: 1px solid #f3f4f4;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #24232d; }
  .form-control:focus {
    color: #24232d;
    background-color: #f3f4f4;
    border-color: #7bcae4;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #d4d4d4;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #808080;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #24232d;
  background-color: #f3f4f4; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 10px 10px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #28a745;
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #f3f4f4 no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 10px 10px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #dc3545;
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #f3f4f4 no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 2px 0;
  overflow: hidden;
  border-top: 1px solid #808080; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #4c4c4c; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: DINPro, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #135eb3; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #135eb3; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #135eb3; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #135eb3; }

.tooltip-inner {
  max-width: 200px;
  padding: 10px 10px;
  color: #fff;
  text-align: center;
  background-color: #135eb3;
  border-radius: 0.25rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

@font-face {
  font-family: 'fontello';
  src: url("../font/fontello.eot?50209515");
  src: url("../font/fontello.eot?50209515#iefix") format("embedded-opentype"), url("../font/fontello.woff2?50209515") format("woff2"), url("../font/fontello.woff?50209515") format("woff"), url("../font/fontello.ttf?50209515") format("truetype"), url("../font/fontello.svg?50209515#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?50209515#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-facebook:before {
  content: '\e800'; }

/* '' */
.icon-youtube:before {
  content: '\e801'; }

/* '' */
.icon-instagram:before {
  content: '\e802'; }

/* '' */
body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right); }

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996; }

/*@supports (padding: max(0px)) {
    .fancybox-caption {
        padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left));
    }
}*/
.fancybox-caption--separate {
  margin-top: -50px; }

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px; }
  /* @supports (padding: max(0px)) {
        .fancybox-caption {
            padding-left: max(12px, env(safe-area-inset-left));
            padding-right: max(12px, env(safe-area-inset-right));
        }
    }*/ }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../font/slick.eot");
  src: url("../font/slick.eot?#iefix") format("embedded-opentype"), url("../font/slick.woff") format("woff"), url("../font/slick.ttf") format("truetype"), url("../font/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.fancybox-bg {
  background-color: #181819; }

.fancybox-is-open .fancybox-bg {
  opacity: .34; }

.slick-list {
  display: flex;
  width: 100%; }

.slick-track {
  display: flex; }

.slick-slide {
  float: none;
  height: auto; }

.slick-initialized .slick-slide {
  display: flex; }

.slick-dots li {
  vertical-align: top;
  margin: 0 5px;
  width: 24px;
  height: 24px;
  padding: 6px 0; }
  .slick-dots li button {
    position: relative;
    width: 24px;
    height: 12px;
    background-color: #ffffff;
    transition: border-color .3s; }
    .slick-dots li button:before {
      display: none; }
  .slick-dots li.slick-active button {
    background-color: #b7161e; }

.slider-controls {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .slider-controls .slick-dots {
    position: relative;
    margin: 0 10px;
    width: auto;
    bottom: 0; }

.slider-prev,
.slider-next {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 0;
  color: #e8e8e8;
  text-align: center;
  cursor: pointer;
  transition: color .3s; }
  .slider-prev:hover,
  .slider-next:hover {
    color: #ffde01; }
  .slider-prev:before,
  .slider-next:before {
    font-family: "fontello";
    content: '\e80b';
    margin-left: -2px;
    font-size: 14px; }

.slider-next:before {
  content: '\e80c';
  margin-left: 2px; }

.slider {
  display: flex;
  flex-wrap: nowrap;
  min-width: 100%;
  overflow: hidden; }
  .slider > * {
    flex: 0 0 100%; }

.slick-slide > div {
  flex-grow: 1;
  display: flex;
  min-width: 0; }
  .slick-slide > div > [class*="col"] {
    display: flex !important;
    flex: 0 0 100%;
    max-width: 100%; }

.popup {
  display: none;
  width: 100%;
  max-width: 400px;
  padding: 30px 40px;
  border-radius: 10px;
  box-shadow: 0 0 38px rgba(19, 19, 19, 0.16); }
  .popup .btn {
    padding: 0 32px;
    height: 40px; }

.popup__close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  font-size: 0;
  background-color: transparent;
  cursor: pointer; }
  .popup__close:before, .popup__close:after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -11px;
    content: '';
    width: 22px;
    height: 2px;
    background-color: #6f7375;
    transition: background-color .3s; }
  .popup__close:before {
    transform: rotate(45deg); }
  .popup__close:after {
    transform: rotate(-45deg); }
  .popup__close:hover:before, .popup__close:hover:after {
    background-color: red; }

.popup__tab-callers {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px; }
  .popup__tab-callers:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -1px;
    content: '';
    width: 2px;
    height: 24px;
    background-color: #b1b2b3; }

.popup__tab-caller {
  font-family: Bebas, sans-serif;
  font-size: 30px;
  color: #b1b2b3;
  text-transform: uppercase;
  transition: color .3s;
  cursor: pointer; }
  .popup__tab-caller:hover, .popup__tab-caller.active {
    color: #7bcae4; }

.popup__tab {
  display: none; }
  .popup__tab.active {
    display: block; }

#popup-thanks {
  max-width: 300px; }

.thanks__title {
  margin-bottom: 16px;
  text-align: center;
  font-size: 24px;
  color: #a7282e; }

.thanks__text {
  text-align: center;
  font-size: 16px;
  color: #b1b2b3; }

/* font-weight: 100          - thin
 * font-weight: 200          - ultralight
 * font-weight: 300          - light
 * font-weight: 400 (normal) - regular
 * font-weight: 500          - medium
 * font-weight: 600          - semibold
 * font-weight: 700 (bold)   - bold
 * font-weight: 800          - extrabold
 * font-weight: 900          - black
 */
@font-face {
  font-family: DINPro;
  src: url("../font/DINPro-Bold.otf") format("truetype");
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: DINPro;
  src: url("../font/DINPro-Regular.otf") format("truetype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: DINPro;
  src: url("../font/DINPro-Light.otf") format("truetype");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: Bebas;
  src: url("../font/BebasNeue.otf") format("truetype");
  font-style: normal;
  font-weight: normal; }

body {
  line-height: 1.3;
  font-size: 14px;
  color: #24232d; }

img {
  max-width: 100%; }

a:focus, button:focus, input:focus, textarea:focus, select:focus {
  outline: none; }

a {
  color: inherit; }
  a:hover {
    color: inherit;
    text-decoration: none; }

b {
  font-weight: bold; }

h1, .h1 {
  font-size: 36px; }

h2, .h2 {
  font-size: 36px; }

h3, .h3 {
  font-size: 25px; }

h4, .h4 {
  font-size: 20px; }

h5, .h5 {
  font-size: 18px; }

h6, .h6 {
  font-size: 16px; }

p:last-child {
  margin-bottom: 0; }

.color-green {
  color: #5ac862 !important; }

.color-red {
  color: #ea2e2e !important; }

.color-gray {
  color: #6e6e6e !important; }

.color-blue {
  color: #1776e1 !important; }

.form-group {
  margin-bottom: 10px; }

.form-label {
  margin-bottom: 6px;
  font-size: 15px; }

.form-control-group {
  display: flex; }
  .form-control-group .form-label {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 20px;
    padding-right: 12px;
    line-height: 50px;
    font-size: 16px; }
  .form-control-group .form-item:first-child .form-control {
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .form-control-group .form-item:last-child .form-control {
    margin-left: -2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .form-control-group .form-control {
    padding-left: 46px;
    padding-right: 28px !important; }

.form-price .form-label:before {
  position: absolute;
  right: 18px;
  top: 0;
  font-family: "fontello";
  content: '\f158';
  font-size: 12px;
  color: #1776e1; }

.form-item {
  position: relative; }
  .form-item .form-control {
    padding-right: 42px; }
  .form-item [class^="icon-"], .form-item [class*=" icon-"],
  .form-item .positive-item,
  .form-item .negative-item {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    right: 12px;
    width: 30px;
    height: 30px;
    padding: 0;
    z-index: 2; }
    .form-item [class^="icon-"]:before, .form-item [class*=" icon-"]:before,
    .form-item .positive-item:before,
    .form-item .negative-item:before {
      margin: 0;
      background-color: #fff; }
  .form-item [class^="icon-"], .form-item [class*=" icon-"] {
    line-height: 30px;
    color: #1876e1;
    text-align: center;
    border-radius: 50%;
    background-color: #fff; }
    .form-item [class^="icon-"]:before, .form-item [class*=" icon-"]:before {
      width: auto;
      margin-left: 0;
      margin-right: 0; }

.block-form {
  padding: 50px 40px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.11); }
  .block-form .block-border {
    width: 100%;
    max-width: 370px; }

.block-form__inner {
  margin: 0 auto;
  max-width: 960px; }

.form-control {
  position: relative;
  padding: 10px 19px; }
  .form-control:focus {
    z-index: 1; }

.form-control__gray {
  border-color: #f0efef;
  background-color: #f0efef; }
  .form-control__gray:focus {
    border-color: #1876e1;
    background-color: #f0efef; }

.select-custom {
  position: relative; }
  .select-custom:before {
    position: absolute;
    content: '';
    top: 50%;
    right: 13px;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    pointer-events: none;
    z-index: 2; }
  .select-custom:after {
    position: absolute;
    content: '';
    top: 50%;
    margin-top: -1px;
    right: 22px;
    border-top: 4px solid #1876e1;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    pointer-events: none;
    z-index: 2; }
  .select-custom select.form-control {
    appearance: none;
    border-color: #f0efef;
    background-color: #f0efef;
    cursor: pointer; }

.form-control__sm {
  width: 70px; }

.form-control__md {
  width: 120px; }

textarea.form-control {
  min-height: 155px;
  max-height: 200px; }

.dropdown-menu {
  min-width: auto;
  margin-top: 10px;
  padding: 10px 24px;
  border: none;
  border-radius: 0;
  box-shadow: 0 0 18px rgba(19, 19, 19, 0.1); }

.dropdown-menu-arrow:before {
  position: absolute;
  top: -8px;
  right: 14px;
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotate(45deg); }

.dropdown-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .dropdown-list a {
    position: relative;
    display: inline-block;
    padding: 2px 0; }
    .dropdown-list a:before {
      position: absolute;
      bottom: 4px;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #6f7375;
      opacity: 0;
      transition: opacity .3s; }
    .dropdown-list a.active:before, .dropdown-list a:hover:before {
      opacity: 1; }

#page {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 320px;
  min-height: 100vh;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); }

#page-content {
  position: relative;
  flex-grow: 1; }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  max-width: 1200px; }

.logo,
.logo-big {
  display: inline-block;
  width: 100%;
  width: 140px; }
  .logo img,
  .logo-big img {
    width: 100%; }

.logo-big {
  width: 330px; }

.h-quote:after {
  display: block;
  content: '';
  margin: 12px auto 30px;
  width: 46px;
  height: 35px;
  background: url(../img/quote.png) center no-repeat;
  background-size: contain; }

.text {
  font-size: 22px;
  margin-bottom: 20px; }
  .text:last-child {
    margin-bottom: 0; }

.bg {
  background: center no-repeat;
  background-size: cover; }

.bg-white {
  background-color: #fff; }

.block-shadow {
  padding: 30px 40px;
  background-color: #fff;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.11); }

.block-border {
  padding: 30px;
  border-radius: 4px;
  border: 1px solid #e5e5e5; }

.block-sections {
  display: flex;
  background-color: #fff;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.11); }

.block-sections__section {
  width: 50%;
  padding: 40px; }
  .block-sections__section:last-child {
    border-left: 1px solid #e6e5e5; }

.block-left-border {
  position: relative;
  margin-bottom: 30px;
  padding-left: 30px; }
  .block-left-border:last-child {
    margin-bottom: 0; }
  .block-left-border:before {
    position: absolute;
    content: '';
    top: 4px;
    left: 0;
    bottom: 4px;
    width: 6px;
    background-color: #1876e1; }

.h-bebas {
  font-family: Bebas, sans-serif; }

.typography p, .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography ul, .typography ol {
  margin-bottom: 14px; }
  .typography p:last-child, .typography h1:last-child, .typography h2:last-child, .typography h3:last-child, .typography h4:last-child, .typography h5:last-child, .typography h6:last-child, .typography ul:last-child, .typography ol:last-child {
    margin-bottom: 0; }

.typography h1 {
  font-size: 45px; }

.typography h2 {
  font-size: 30px; }

.typography h3 {
  font-size: 25px; }

.typography h4 {
  font-size: 20px; }

.typography h5 {
  font-size: 18px; }

.typography h6 {
  font-size: 16px; }

.typography strong {
  color: #a8272e;
  font-weight: inherit; }

.typography img {
  margin-top: 28px;
  margin-bottom: 28px;
  max-width: 100%; }
  .typography img:first-child {
    margin-top: 0; }
  .typography img:last-child {
    margin-bottom: 0; }

.typography ol {
  padding-left: 20px; }

.typography ul {
  list-style: none;
  padding-left: 34px; }
  .typography ul li:last-child {
    margin-bottom: 0; }
  .typography ul li:before {
    position: absolute;
    content: '';
    margin-top: 10px;
    margin-left: -34px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #1876e1; }

.typography blockquote {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 580px;
  padding-left: 26px;
  line-height: 28px;
  font-size: 20px; }
  .typography blockquote:before {
    position: absolute;
    top: 6px;
    left: 0;
    bottom: 8px;
    content: '';
    width: 6px;
    background-color: #1876e1; }
  .typography blockquote:first-child {
    margin-top: 0; }
  .typography blockquote:last-child {
    margin-bottom: 0; }

.fs-14 {
  line-height: 14px !important;
  font-size: 14px; }

.fs-12 {
  font-size: 12px !important; }

.list-true li:before,
.list-false li:before {
  position: absolute;
  font-family: "fontello";
  margin-top: 4px !important;
  margin-left: -34px;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px;
  border-radius: 50%;
  background-color: transparent !important; }

.list-true li:before {
  content: '\e805' !important;
  color: #5ac862; }

.list-false li:before {
  content: '\e80d' !important;
  color: #ea2f2f; }

.page-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 45px;
  height: 90px;
  padding: 20px 30px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.11); }

._js_filter-toggle-item {
  display: none; }

body.overflow {
  overflow: hidden; }

/*  burger
========================================== */
.burger {
  display: none;
  position: relative;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 22px;
  right: 5px;
  z-index: 16;
  border-radius: 0;
  background-color: transparent;
  border: none;
  transition: border-color .3s, background-color .3s; }

.burger span {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -1px; }

.burger span, .burger span::before, .burger span::after {
  display: block;
  width: 24px;
  height: 3px;
  background-color: #a8272e;
  outline: 1px solid transparent;
  transition-property: background-color, transform;
  transition-duration: .3s; }

.burger span::before, .burger span::after {
  position: absolute;
  content: ""; }

.burger span::before {
  top: -7px; }

.burger span::after {
  top: 7px; }

.burger.clicked {
  border-color: transparent;
  background-color: transparent; }

.burger.clicked span {
  background-color: transparent; }

.burger.clicked span::before {
  transform: translateY(7px) rotate(45deg); }

.burger.clicked span::after {
  transform: translateY(-7px) rotate(-45deg); }

.burger.clicked span:before, .burger.clicked span:after {
  background-color: #ffffff; }

.burger:hover {
  cursor: pointer; }

/*  .burger-popup
========================================== */
.burger-popup {
  display: none;
  background-color: #a7282e;
  position: fixed;
  z-index: 14;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px 40px;
  color: #fff;
  font-size: 18px;
  transform: translateX(100%);
  transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.burger-popup.show {
  transform: translateX(0px); }

.burger-popup hr {
  margin-top: 24px;
  margin-bottom: 24px;
  opacity: .2; }

.burger-nav {
  list-style: none;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-size: 22px; }
  .burger-nav li {
    margin-bottom: 8px; }
    .burger-nav li:last-child {
      margin-bottom: 0; }
  .burger-nav a {
    padding-left: 20px; }
    .burger-nav a:before {
      position: absolute;
      content: '';
      margin-top: 10px;
      margin-left: -20px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff; }
  .burger-nav a:active, .burger-nav a.active {
    color: #fa4b67; }
    .burger-nav a:active:before, .burger-nav a.active:before {
      background-color: currentColor; }

/*  overlay
========================================== */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in-out;
  background-color: #1e1e1e;
  opacity: 0;
  visibility: hidden; }

.overlay.show {
  opacity: .5;
  visibility: visible; }

header {
  display: flex;
  align-items: center;
  height: 80px;
  color: #fff;
  background-color: #dedfdf;
  z-index: 10; }

.header-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: Bebas, sans-serif;
  font-size: 24px;
  text-transform: uppercase; }
  .header-nav li {
    margin-right: 34px; }
  .header-nav a {
    padding: 4px 12px;
    border-bottom: 2px solid currentColor;
    transition: color .3s; }
    .header-nav a.active, .header-nav a:hover {
      color: #a8272e; }

footer {
  padding: 30px 0;
  font-size: 16px;
  color: #fff;
  background-color: #a7282e; }

.footer__inner {
  margin-bottom: -40px; }
  .footer__inner > div {
    max-width: 300px;
    margin-bottom: 40px; }

.footer-heading {
  margin-bottom: 20px;
  font-family: Bebas, sans-serif;
  font-size: 24px;
  text-transform: uppercase; }

.footer__nav {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  text-transform: uppercase; }
  .footer__nav li {
    margin-bottom: 4px; }
  .footer__nav a {
    transition: color .3s; }
    .footer__nav a:hover {
      color: #fa4b67; }

.my-calendar {
  width: 100%;
  max-width: 140px;
  font-size: 16px; }

.my-calendar__heading {
  margin-bottom: 4px;
  font-size: 16px;
  color: #fa4b67;
  text-transform: uppercase; }

.my-calendar__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -8px; }

.my-calendar__item {
  margin-bottom: 4px;
  min-width: 12px;
  margin-right: 2px;
  transition: color .3s; }
  .my-calendar__item:hover, .my-calendar__item.active {
    color: #fa4b67; }

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 50px;
  padding: 0 20px;
  font-family: Bebas, sans-serif;
  font-size: 24px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  border: none;
  outline: none;
  background-color: #a6262d;
  transition: background-color .3s; }
  .btn:hover {
    color: #fff;
    background-color: #7d1d22; }

.btn-bg {
  font-size: 36px; }

.btn-blue {
  background-color: #4199c9; }
  .btn-blue:hover {
    color: #fff;
    background-color: #307da7; }

.icon-auto:before {
  width: auto;
  margin-left: 0;
  margin-right: 0; }

.social-items {
  margin-right: -12px; }
  .social-items .social-item {
    margin-right: 12px; }

.social-item {
  position: relative;
  vertical-align: middle;
  line-height: 1;
  font-size: 26px;
  color: #fff;
  transition: color .3s; }
  .social-item [class^="icon-"], .social-item [class*=" icon-"] {
    margin: auto; }
    .social-item [class^="icon-"]:before, .social-item [class*=" icon-"]:before {
      width: auto;
      margin-left: 0;
      margin-right: 0; }
  .social-item:hover {
    color: #fa4b67; }

.social-item-big {
  font-size: 34px; }

.entrance {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.entrance__section {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover; }
  .entrance__section:first-child {
    left: 0;
    background-position: top left; }
  .entrance__section:last-child {
    left: 50%;
    background-position: top right; }

.entrance__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.btn-entrance {
  min-width: 170px; }

.main-slider {
  position: relative;
  display: flex;
  min-height: 100vh;
  margin-top: -80px;
  padding-top: 80px; }

.main {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  overflow: hidden; }

.main_blue .main__title {
  color: #0073ac; }

.main_blue .main__bottom {
  background-color: #c8e7f1; }
  .main_blue .main__bottom:before {
    background-color: #c8e7f1; }

.main_aqua .main__title {
  color: #007e8a; }

.main_aqua .main__bottom {
  background-color: #c2f7f7; }
  .main_aqua .main__bottom:before {
    background-color: #c2f7f7; }

.main_green .main__title {
  color: #479434; }

.main_green .main__bottom {
  background-color: #c8f1d5; }
  .main_green .main__bottom:before {
    background-color: #c8f1d5; }

.main_red .main__title {
  color: #a8272e; }

.main_red .main__bottom {
  background-color: #f1cbc8; }
  .main_red .main__bottom:before {
    background-color: #f1cbc8; }

.main_pink .main__title {
  color: #e80071; }

.main_pink .main__bottom {
  background-color: #f4d5e1; }
  .main_pink .main__bottom:before {
    background-color: #f4d5e1; }

.main__inner {
  position: relative;
  flex-grow: 1;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  z-index: 2; }

.main__section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .main__section:first-child {
    width: 400px;
    min-width: 400px;
    padding-right: 20px; }
  .main__section:last-child {
    flex-grow: 1; }

.main__content {
  min-height: 50%;
  padding-bottom: 10px; }

.main__img {
  position: absolute;
  display: flex;
  top: 0;
  bottom: -40px;
  width: 100%; }
  .main__img img {
    max-height: 100%;
    margin: auto auto 0; }

.main__img-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 2; }

.main__img-shadow {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  height: 110px;
  background-color: red;
  background: center top no-repeat;
  background-size: 100% auto;
  transform: translateX(-50%); }

.main__title {
  margin-bottom: 20px;
  font-size: 40px;
  text-transform: uppercase; }
  .main__title span {
    background: linear-gradient(to right, currentColor, currentColor 100%);
    background-position: 0 100%;
    background-size: 100% 3px;
    background-repeat: repeat-x; }

.main__text {
  font-size: 25px;
  color: #848989; }

.main__bottom {
  position: relative;
  display: flex;
  height: 110px;
  padding-left: 400px;
  background-color: #c8e7f1; }
  .main__bottom:before {
    position: absolute;
    left: 100%;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #c8e7f1; }

.main__bottom-inner {
  width: 100%;
  background: center top no-repeat;
  background-size: contain; }

.main-pager {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 110px; }
  .main-pager .slick-dots {
    position: relative;
    bottom: 0;
    text-align: left; }

.main-pager__inner {
  padding: 19px; }

.event-slider {
  position: relative;
  display: flex;
  min-height: 100vh; }
  .event-slider:first-child {
    margin-top: -80px;
    padding-top: 80px; }

.event {
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  min-height: 400px;
  padding: 40px 0 70px;
  color: #fff;
  text-align: center;
  background: center center no-repeat;
  background-size: cover; }

.event__title {
  line-height: 60px;
  font-family: Bebas, sans-serif;
  font-size: 68px; }

.event__text {
  margin-bottom: 30px;
  font-size: 22px; }

.event-pager {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  z-index: 2; }
  .event-pager .slick-dots {
    position: static; }

.card {
  position: relative; }
  .card hr {
    border-top: 3px solid #bcbcbc; }

.card__body {
  overflow: hidden; }

.card__sections {
  display: flex;
  padding: 0 15px; }

.card__section {
  display: flex;
  flex-direction: column;
  width: 50%; }
  .card__section:first-child {
    align-items: flex-end;
    padding-right: 30px; }
  .card__section:last-child {
    align-items: flex-start;
    padding-left: 30px; }

.card_blue .card__img-slide {
  background-color: #c8e7f1; }

.card_blue .card__title {
  color: #0179c0;
  border-bottom: 3px solid #0179c0; }

.card_blue .card__subtitle {
  color: #0179c0; }

.card_aqua .card__img-slide {
  background-color: #c1f7f7; }

.card_aqua .card__title {
  color: #007f89;
  border-bottom: 3px solid #007f89; }

.card_aqua .card__subtitle {
  color: #007f89; }

.card_green .card__img-slide {
  background-color: #c8f1d5; }

.card_green .card__title {
  color: #4a9536;
  border-bottom: 3px solid #4a9536; }

.card_green .card__subtitle {
  color: #4a9536; }

.card_red .card__img-slide {
  background-color: #f1cbc8; }

.card_red .card__title {
  color: #a8272e;
  border-bottom: 3px solid #a8272e; }

.card_red .card__subtitle {
  color: #a8272e; }

.card_pink .card__img-slide {
  background-color: #f9e6ee; }

.card_pink .card__title {
  color: #e80071;
  border-bottom: 3px solid #e80071; }

.card_pink .card__subtitle {
  color: #e80071; }

.card__img {
  position: relative;
  flex-grow: 1;
  display: flex;
  width: 100%;
  max-width: 555px;
  overflow: hidden; }

.card__img-slide {
  position: absolute;
  flex-shrink: 0;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  max-height: 550px;
  width: 100%;
  padding: 30px;
  background-color: #c8e7f1;
  opacity: 0;
  transition: opacity .5s; }
  .card__img-slide img {
    max-width: 70%;
    max-height: 90%; }
  .card__img-slide.active {
    position: relative;
    opacity: 1;
    z-index: 1; }

.card__content {
  position: relative;
  flex-grow: 1;
  max-width: 555px;
  min-height: 290px;
  margin-right: 220px;
  overflow: hidden; }

.card__content-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity .5s; }
  .card__content-item.active {
    position: relative;
    opacity: 1;
    z-index: 1; }

.card__title {
  margin-bottom: 6px;
  padding-bottom: 6px;
  font-size: 30px;
  color: #0179c0;
  text-transform: uppercase;
  border-bottom: 3px solid #0179c0; }

.card__subtitle {
  margin-bottom: 6px;
  font-size: 18px;
  color: #0179c0; }

.data-item {
  margin-bottom: 6px;
  margin-right: 30px;
  font-size: 18px;
  color: #848989; }
  .data-item:last-child {
    margin-right: 0; }

.card-pager {
  display: flex;
  margin-top: 36px;
  max-width: 100%; }
  .card-pager .slick-slide {
    margin-right: 60px; }
    .card-pager .slick-slide .card-pager-item {
      margin: 0; }

.card-pager__pager {
  display: flex;
  overflow: hidden; }
  .card-pager__pager > div {
    display: flex;
    transition: transform .5s; }

.card-pager__next {
  display: none;
  width: 60px;
  min-width: 60px;
  height: 220px;
  background: url(../img/arrow-next.png) center center no-repeat;
  border: 3px solid transparent;
  transition: border-color .3s;
  cursor: pointer; }
  .card-pager__next.active {
    display: block; }
  .card-pager__next:hover {
    border-color: #d9e0e2; }

.card-pager-item {
  display: flex !important;
  align-items: center;
  margin-right: 40px;
  height: 220px;
  padding: 10px 20px;
  border: 3px solid transparent;
  transition: border-color .3s;
  cursor: pointer; }
  .card-pager-item img {
    max-width: 100px;
    max-height: 90%; }
  .card-pager-item:hover, .card-pager-item.active {
    border-color: #d9e0e2; }

.nav-line {
  position: absolute;
  top: 0;
  right: 0;
  width: 220px;
  z-index: 8; }
  .nav-line a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 58px;
    padding: 10px 20px;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    transition: background-color .3s; }
    .nav-line a:nth-child(1) {
      background-color: #7bd2ed; }
      .nav-line a:nth-child(1):hover, .nav-line a:nth-child(1).active {
        background-color: #0179c0; }
    .nav-line a:nth-child(2) {
      background-color: #a4e9ec; }
      .nav-line a:nth-child(2):hover, .nav-line a:nth-child(2).active {
        background-color: #007f89; }
    .nav-line a:nth-child(3) {
      background-color: #b8fbb6; }
      .nav-line a:nth-child(3):hover, .nav-line a:nth-child(3).active {
        background-color: #4a9536; }
    .nav-line a:nth-child(4) {
      background-color: #f7e0df; }
      .nav-line a:nth-child(4):hover, .nav-line a:nth-child(4).active {
        background-color: #a8272e; }
    .nav-line a:nth-child(5) {
      background-color: #f9e6ee; }
      .nav-line a:nth-child(5):hover, .nav-line a:nth-child(5).active {
        background-color: #e80071; }

.webinar-card {
  display: flex;
  margin-bottom: -30px; }

.webinar-card__img {
  margin-bottom: 30px;
  width: 35%;
  min-width: 35%; }

.webinar-card__content {
  position: relative;
  margin-bottom: 30px;
  flex-grow: 1;
  padding-left: 50px;
  padding-right: 30px; }
  .webinar-card__content:before {
    position: absolute;
    top: 98px;
    right: 0;
    content: '';
    width: 4px;
    height: 200px;
    background-color: #d9d9d9; }

.webinar-card__list {
  margin-bottom: 30px;
  min-width: 160px;
  padding-top: 94px;
  padding-left: 30px; }

.webinar-card__top {
  display: flex;
  align-items: center;
  height: 98px;
  padding: 12px 0; }

.webinar-card__date {
  margin-right: 20px;
  margin-bottom: 10px;
  font-family: Bebas, sans-serif;
  font-size: 44px;
  color: #0570ab; }

.webinar-card__time {
  margin-top: 6px;
  margin-bottom: 10px;
  font-family: Bebas, sans-serif;
  line-height: 46px;
  font-size: 30px;
  color: #999; }

.webinar-card__header {
  display: flex;
  margin-left: -50px;
  margin-bottom: 16px;
  padding: 12px 30px 30px 50px;
  color: #fff;
  background-color: #c8e7f1;
  transition: background-color .3s; }
  .webinar-card__header:hover {
    color: #fff;
    background-color: #9fd5e7; }

.webinar-card__btn {
  padding: 0 12px;
  font-size: 32px; }

.webinar-card__label {
  margin-bottom: 6px;
  font-size: 24px; }

.webinar-card__title {
  font-family: Bebas, sans-serif;
  font-size: 34px;
  color: #a8272e;
  text-transform: uppercase; }

.webinar-card__duration {
  align-self: flex-end;
  font-family: Bebas, sans-serif;
  margin-top: 20px;
  font-size: 34px;
  white-space: nowrap; }

.webinar-card__name {
  margin-bottom: 20px;
  font-size: 24px;
  color: #0570ab;
  text-transform: uppercase; }
  .webinar-card__name:before {
    display: block;
    content: '';
    margin-bottom: 16px;
    width: 100%;
    max-width: 320px;
    height: 3px;
    background-color: currentColor; }

.webinar-card__text {
  font-size: 18px;
  color: #484847; }

.webinar-list-label {
  margin-bottom: 26px;
  font-size: 18px;
  color: #a8272e;
  text-transform: uppercase; }

.webinar-item {
  display: block;
  margin-left: -10px;
  margin-bottom: 10px;
  padding: 10px;
  transition: background-color .3s; }
  .webinar-item:hover {
    background-color: #c8e7f1; }
  .webinar-item:last-child {
    margin-bottom: 0; }

.webinar-item__date,
.webinar-item__name {
  font-size: 18px;
  color: #484847; }

.webinar-item__title {
  font-size: 18px;
  color: #0570ab;
  text-transform: uppercase; }

.seminar-block {
  position: relative;
  padding-left: 25px; }

.seminar-heading {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 156px;
  font-size: 24px;
  color: #9c9c9c;
  text-transform: uppercase; }

.seminar-label {
  position: absolute;
  top: -20px;
  left: -15px;
  width: 170px;
  height: 50px;
  line-height: 50px;
  font-family: Bebas, sans-serif;
  font-size: 36px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background: #a7272e; }

.seminar-items {
  column-count: 2;
  column-gap: 30px; }

.seminar-item {
  display: inline-flex;
  width: 100%;
  color: #484847; }

.seminar-item__data {
  margin-right: 26px;
  width: 130px;
  min-width: 130px;
  padding-top: 46px;
  padding-right: 12px;
  text-align: right;
  border-right: 3px solid #e1e2e2; }

.seminar-item__data-inner {
  display: inline-block;
  text-align: left; }

.seminar-item__content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  width: 100%;
  min-height: 96px;
  padding: 26px 26px 18px 18px;
  background-color: #dedfdf;
  transition: background-color .3s; }
  .seminar-item__content:hover {
    background-color: #c8e7f1; }

.seminar-item__date {
  line-height: 36px;
  font-size: 36px;
  color: #a7272e; }

.seminar-item__time {
  font-size: 18px; }

.seminar-item__title {
  font-family: Bebas, sans-serif;
  font-size: 30px;
  text-transform: uppercase; }

.seminar-item__name {
  font-size: 18px; }

.seminar-item__link {
  display: inline-block;
  align-self: flex-end;
  font-family: Bebas, sans-serif;
  margin-bottom: -6px;
  padding: 6px 0;
  font-size: 24px;
  color: #a72930;
  text-transform: uppercase;
  transition: color .3s; }
  .seminar-item__link:hover {
    color: #fff; }

@media (max-width: 1199.98px) {
  .card__section:first-child {
    width: 40%;
    padding-right: 15px; }
  .card__section:last-child {
    width: 60%;
    padding-left: 15px; }
  .webinar-card__title {
    font-size: 3vw; }
  .webinar-card__duration {
    font-size: 3vw; }
  .seminar-label {
    width: 140px;
    height: 44px;
    line-height: 44px;
    font-size: 30px; }
  .seminar-heading {
    padding-left: 126px; }
  .seminar-item__data {
    margin-right: 20px;
    width: 100px;
    min-width: 100px;
    padding-right: 12px; }
  .seminar-item__date {
    line-height: 32px;
    font-size: 32px; }
  .seminar-item__time {
    font-size: 16px; } }

@media (max-width: 991.98px) {
  .card__content {
    margin-right: 170px; }
  .nav-line {
    width: 170px; }
    .nav-line a {
      height: 48px;
      padding: 6px 10px;
      font-size: 16px; }
  .card-pager-item {
    margin-right: 20px;
    height: 170px;
    border: 2px solid transparent; }
  .card-pager__next {
    width: 40px;
    min-width: 40px;
    height: 170px;
    border: 2px solid transparent; }
  .webinar-card {
    flex-wrap: wrap; }
  .webinar-card__content {
    width: 65%;
    padding-right: 0; }
    .webinar-card__content:before {
      display: none; }
  .webinar-card__top {
    height: auto; }
  .webinar-card__list {
    padding: 0; }
  .footer__inner > div {
    max-width: 100%; }
  .event__title {
    line-height: 50px;
    font-size: 58px; }
  .seminar-items {
    column-count: 1; }
  .footer__social {
    display: flex;
    align-items: center;
    justify-content: center; }
    .footer__social > div {
      margin-left: 20px;
      margin-right: 20px; } }

@media (max-width: 767.98px) {
  .burger, .burger-popup, .overlay {
    display: block; }
  .header-nav {
    display: none; }
  .main__inner {
    flex-wrap: wrap; }
  .main__section:first-child {
    width: 100%;
    min-width: 0;
    padding: 0; }
  .main__section:last-child {
    width: 100%;
    flex-grow: 1; }
  .main__img {
    position: relative;
    top: auto; }
    .main__img img {
      max-height: 300px; }
  .main__bottom {
    margin-left: -15px; }
  .card__sections {
    display: block; }
  .card__section:first-child {
    align-items: flex-start;
    width: 100%;
    padding-right: 170px;
    margin-bottom: 30px; }
  .card__section:last-child {
    width: 100%;
    padding: 0; }
  .card__img {
    min-height: 240px; }
  .card__img-slide {
    max-height: 450px; }
  .card__content {
    max-width: 100%;
    min-height: 0;
    margin: 0; }
  .webinar-card__img {
    width: 100%; }
  .webinar-card__content {
    width: 100%;
    padding: 0; }
  .webinar-card__header {
    margin-right: -15px;
    padding-right: 45px; }
  .webinar-card__title {
    font-size: 30px; }
  .webinar-card__duration {
    font-size: 30px; }
  .event__title {
    line-height: 40px;
    font-size: 48px; } }

@media (max-width: 575.98px) {
  .main__img {
    position: relative;
    top: auto; }
    .main__img img {
      max-height: 200px; }
  .card__section:first-child {
    padding-right: 120px; }
  .card__img-slide {
    max-height: 350px; }
  .nav-line {
    width: 120px; }
    .nav-line a {
      min-height: 40px;
      padding: 4px 10px;
      font-size: 13px; }
  .card-pager-item {
    margin-right: 10px;
    height: 120px; }
  .card-pager__next {
    height: 120px; }
  .webinar-card__header {
    flex-wrap: wrap; }
  .event__title {
    line-height: 30px;
    font-size: 38px; }
  .seminar-block {
    padding: 0; }
  .seminar-label {
    position: relative;
    top: 0;
    left: 0; }
  .seminar-heading {
    padding-left: 0; }
  .seminar-item {
    display: block; }
  .seminar-item__data {
    margin-bottom: 10px;
    padding: 0;
    text-align: left;
    border: none; }
  .seminar-item__content {
    display: block; }
  .seminar-item__link {
    margin-top: 20px; }
  .footer__social {
    display: block; }
    .footer__social > div {
      margin-left: 0;
      margin-right: 0; } }
