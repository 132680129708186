.card {
	position: relative;
	hr {
		border-top: 3px solid #bcbcbc;
	}
}

.card__body {
	// transition: height 3s;
	overflow: hidden;
}

.card__sections {
	display: flex;
	padding: 0 15px;
}

.card__section {
	display: flex;
	flex-direction: column;
	width: 50%;
	&:first-child {
		align-items: flex-end;
		padding-right: 30px;
	}
	&:last-child {
		align-items: flex-start;
		padding-left: 30px;
		// padding-right: 220px;
	}
}

.card_blue {
	.card__img-slide {
		background-color: #c8e7f1;
	}
	.card__title {
		color: #0179c0;
		border-bottom: 3px solid #0179c0;
	}

	.card__subtitle {
		color: #0179c0;
	}
}

.card_aqua {
	.card__img-slide {
		background-color: #c1f7f7;
	}
	.card__title {
		color: #007f89;
		border-bottom: 3px solid #007f89;
	}

	.card__subtitle {
		color: #007f89;
	}
}

.card_green {
	.card__img-slide {
		background-color: #c8f1d5;
	}
	.card__title {
		color: #4a9536;
		border-bottom: 3px solid #4a9536;
	}

	.card__subtitle {
		color: #4a9536;
	}
}

.card_red {
	.card__img-slide {
		background-color: #f1cbc8;
	}
	.card__title {
		color: #a8272e;
		border-bottom: 3px solid #a8272e;
	}

	.card__subtitle {
		color: #a8272e;
	}
}

.card_pink {
	.card__img-slide {
		background-color: #f9e6ee;
	}
	.card__title {
		color: #e80071;
		border-bottom: 3px solid #e80071;
	}

	.card__subtitle {
		color: #e80071;
	}
}

.card__img {
	position: relative;
	flex-grow: 1;
	display: flex;
	width: 100%;
	max-width: 555px;
	overflow: hidden;
}

.card__img-slide {
	position: absolute;
	flex-shrink: 0;
	display: flex !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	max-height: 550px;
	width: 100%;
	padding: 30px;
	background-color: #c8e7f1;
	opacity: 0;
	transition: opacity .5s;
	img {
		max-width: 70%;
		max-height: 90%;
	}
	&.active {
		position: relative;
		opacity: 1;
		z-index: 1;
	}
}

.card__content {
	position: relative;
	// width: 100%;
	flex-grow: 1;
	max-width: 555px;
	// max-width: 775px;
	min-height: 290px;
	margin-right: 220px;
	overflow: hidden;
}

.card__content-item {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	opacity: 0;
	transition: opacity .5s;
	&.active {
		position: relative;
		opacity: 1;
		z-index: 1;
	}
}

.card__title {
	margin-bottom: 6px;
	padding-bottom: 6px;
	font-size: 30px;
	color: #0179c0;
	text-transform: uppercase;
	border-bottom: 3px solid #0179c0;
}

.card__subtitle {
	margin-bottom: 6px;
	font-size: 18px;
	color: #0179c0;
}

.data-item {
	margin-bottom: 6px;
	margin-right: 30px;
	font-size: 18px;
	color: #848989;
	&:last-child {
		margin-right: 0;
	}
}

.card-pager {
	display: flex;
	margin-top: 36px;
	max-width: 100%;
	.slick-slide {
		margin-right: 60px;
		.card-pager-item {
			margin: 0;
		}
	}
}

.card-pager__pager {
	display: flex;
	overflow: hidden;
	// margin-right: -60px;
	// margin-right: 30px;
	> div {
		display: flex;
		// overflow: hidden;
		transition: transform .5s;
	}
}

.card-pager__next {
	display: none;
	width: 60px;
	min-width: 60px;
	height: 220px;
	background: url(../img/arrow-next.png) center center no-repeat;
	border: 3px solid transparent;
	transition: border-color .3s;
	cursor: pointer;
	&.active {
		display: block;
	}
	&:hover {
		border-color: #d9e0e2;
	}
}

.card-pager-item {
	display: flex !important;
	align-items: center;
	margin-right: 40px;
	height: 220px;
	padding: 10px 20px;
	border: 3px solid transparent;
	transition: border-color .3s;
	cursor: pointer;
	img {
		max-width: 100px;
		max-height: 90%;
	}
	&:hover, &.active {
		border-color: #d9e0e2;
	}
}

.nav-line {
	position: absolute;
	top: 0;
	right: 0;
	width: 220px;
	z-index: 8;
	a {
		display: flex;
		align-items: center;
		width: 100%;
		height: 58px;
		padding: 10px 20px;
		font-size: 20px;
		color: #fff;
		text-transform: uppercase;
		transition: background-color .3s;
		&:nth-child(1) {
			background-color: #7bd2ed;
			&:hover, &.active {
				background-color: #0179c0;
			}
		}
		&:nth-child(2) {
			background-color: #a4e9ec;
			&:hover, &.active {
				background-color: #007f89;
			}
		}
		&:nth-child(3) {
			background-color: #b8fbb6;
			&:hover, &.active {
				background-color: #4a9536;
			}
		}
		&:nth-child(4) {
			background-color: #f7e0df;
			&:hover, &.active {
				background-color: #a8272e;
			}
		}
		&:nth-child(5) {
			background-color: #f9e6ee;
			&:hover, &.active {
				background-color: #e80071;
			}
		}
	}
}