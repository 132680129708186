.btn {
	// position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	// min-width: 170px;
	height: 50px;
	padding: 0 20px;
	font-family: Bebas, sans-serif;
	font-size: 24px;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	// border-radius: 25px;
	// border: 3px solid transparent;
	border: none;
	// background: linear-gradient(to right, #fdc300, #ffd946);
	outline: none;
	background-color: #a6262d;
	transition: background-color .3s;
	// [class^="icon-"], [class*=" icon-"] {
	// 	&:before {
	// 	  	width: auto;
	// 	  	margin-right: 0;
	// 	  	margin-left: 0;
	// 	  	font-size: .9em;
	// 	}
	// }
	// &:before {
	// 	position: absolute;
	// 	content: '';
	// 	width: 100%;
	// 	height: 100%;
	// 	border-radius: 25px;
	// 	background: linear-gradient(to right, #ffd946, #fdc300);
	// 	opacity: 1;
	// 	transition: opacity .3s;
	// }
	&:hover {
		color: #fff;
		background-color: darken(#a6262d, 10%);
		// &:before {
			// opacity: 0;
		// }
	}
	// }
	// > span {
	// 	position: relative;
	// }
}

.btn-bg {
	font-size: 36px;
}

.btn-blue {
	background-color: #4199c9;
	&:hover {
		color: #fff;
		background-color: darken(#4199c9, 10%);
	}
}

// .btn-sm {
// 	height: 40px;
// 	padding: 0 16px;
// }

// .btn-outline {
// 	border: 1px solid #cfcfcf;
// 	background: transparent;
// 	transition: border-color .3s;
// 	&:before {
// 		display: none;
// 	}
// 	&:hover {
// 		border-color: #1876e1;
// 	}
// }

// .btn-green {
// 	background: linear-gradient(to right, #8cbb25, #00b400);
// 	&:before {
// 		background: linear-gradient(to right, #00b400, #8cbb25);
// 	}
// }

// .btn-blue {
// 	color: #fff;
// 	background: #1876e1;
// 	&:before {
// 		display: none;
// 	}
// 	&:hover {
// 		background: darken(#1876e1, 10%);
// 	}
// }

// .btn-inline {
// 	padding: 0 8px;
// 	font-size: 15px;
// 	color: #bdbdbd;
// 	font-weight: bold;
// 	text-transform: uppercase;
// 	border: none;  
//   	background: none;
//   	transition: color .3s;
// 	&:before {
// 		display: none;
// 	}
// 	[class^="icon-"], [class*=" icon-"] {
// 		&:before {
// 	  	width: auto;
// 	  	margin-right: 0;
// 	  	margin-left: 0;
// 	  	vertical-align: middle;
// 	  	font-size: 1.2em;
// 		}
// 	}
// 	&:hover {
// 		color: #ffde01;
// 	}
// }

// .btn-delete {
// 	&:hover {
// 		color: red;
// 	}
// }



// .radio-group {
// 	display: flex;
// 	.radio-item {
// 		margin-left: -3px;
// 	}
// }

// .radio-item {
// 	margin: 0;
// 	cursor: pointer;
// 	input {
// 		display: none;
// 		&:checked + .radio-item__btn {
// 			color: #fff;
// 			background-color: #1876e1;
// 		}
// 	}
// }

// .radio-item__btn {
// 	display: inline-flex;
// 	justify-content: center;
// 	align-items: center;
// 	min-width: 140px;
// 	min-height: 50px;
// 	font-size: 14px;
// 	font-weight: 500;
// 	text-transform: uppercase;
// 	border: 3px solid #1876e1;
// }




