body {
	line-height: 1.3;
	font-size: 14px;
	color: #24232d;
}

img {
  max-width: 100%;
}

a, button, input, textarea, select {
	&:focus {
		outline: none;
	}
}

a {
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

b {
  font-weight: bold;
}

h1, .h1 {
	font-size: 36px;
}

h2, .h2 {
  font-size: 36px;
}

h3, .h3 {
	font-size: 25px;
}

h4, .h4 {
	font-size: 20px;
}

h5, .h5, {
	font-size: 18px;
}

h6, .h6 {
	font-size: 16px;
}

p {
	&:last-child {
		margin-bottom: 0;
	}
}

.color-green {
	color: #5ac862 !important;
}

.color-red {
	color: #ea2e2e !important;
}

.color-gray {
	// color: #aaa6a6 !important;	
	color: #6e6e6e !important;
}

.color-blue {
	color: #1776e1 !important;	
}


.form-group {
	margin-bottom: 10px;
}

.form-label {
	margin-bottom: 6px;
	font-size: 15px;
}

.form-control-group {
	display: flex;
	.form-label {
		position: absolute;
		display: flex;
		justify-content: space-between;
		width: 100%;
		// height: 100%;
		padding-left: 20px;
		padding-right: 12px;
		line-height: 50px;
		font-size: 16px;
	}
	.form-item {
		&:first-child {
			.form-control {
				margin-right: -1px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&:last-child {
			.form-control {
				margin-left: -2px;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	.form-control {
		padding-left: 46px;
		padding-right: 28px !important;
	}
}

.form-price {
	.form-label {
		&:before {
			position: absolute;
			right: 18px;
			top: 0;
			font-family: "fontello";
			content: '\f158';
			font-size: 12px;
			color: #1776e1;
		}
	}
}

.form-item {
	position: relative;
	.form-control {
		padding-right: 42px;
	}
	[class^="icon-"], [class*=" icon-"],
	.positive-item,
	.negative-item, {
		position: absolute;
		top: 50%;
		margin-top: -15px;
		right: 12px;
		width: 30px;
		height: 30px;
		padding: 0;
		// line-height: 30px;
		// color: #1876e1;
		// text-align: center;
		// border-radius: 50%;
		z-index: 2;
		&:before {
			margin: 0;
			background-color: #fff;
		}
	}
	[class^="icon-"], [class*=" icon-"] {
		line-height: 30px;
		color: #1876e1;
		text-align: center;
		border-radius: 50%;
		background-color: #fff;
		&:before {
			// vertical-align: middle;
			width: auto;
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.block-form {
	padding: 50px 40px;
	box-shadow: 0 0 18px rgba(0, 0, 0, .11);
	.block-border {
		width: 100%;
		max-width: 370px;
	}
}

.block-form__inner {
	margin: 0 auto;
	max-width: 960px;
}

.form-control {
	position: relative;
	padding: 10px 19px;
	&:focus {
		z-index: 1;
	}
}

.form-control__gray {
	border-color: #f0efef;
	background-color: #f0efef;
	&:focus {
		border-color: #1876e1;
		background-color: #f0efef;
	}
}

.select-custom {
	position: relative;
	&:before {
		position: absolute;
		content: '';
		top: 50%;
		right: 13px;
		margin-top: -12px;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #fff;
		pointer-events: none;
		z-index: 2;
	}
	&:after {
		position: absolute;
		content: '';
		top: 50%;
		margin-top: -1px;
		right: 22px;
		border-top: 4px solid #1876e1;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;
		pointer-events: none;
		z-index: 2;
	}
	select.form-control {
		appearance: none;
		border-color: #f0efef;
		background-color: #f0efef;
		cursor: pointer;
	}
}

.form-control__sm {
	width: 70px;
}

.form-control__md {
	width: 120px;
}

textarea.form-control {
	min-height: 155px;
	max-height: 200px;
}

.dropdown-menu {
	min-width: auto;
	margin-top: 10px;
	padding: 10px 24px;
	border: none;
	border-radius: 0;
	box-shadow: 0 0 18px rgba(19, 19, 19, .1);
}

.dropdown-menu-arrow {
	&:before {
		position: absolute;
		top: -8px;
		right: 14px;
		content: '';
		width: 20px;
		height: 20px;
		background-color: #fff;
		transform: rotate(45deg);
	}
}

.dropdown-list {
	list-style: none;
	margin: 0;
	padding: 0;
	a {
		position: relative;
		display: inline-block;
		padding: 2px 0;
		&:before {
			position: absolute;
			bottom: 4px;
			content: '';
			width: 100%;
			height: 1px;
			background-color: #6f7375;
			opacity: 0;
			transition: opacity .3s;
		}
		&.active, &:hover {
			&:before {
				opacity: 1;
			}
		}
	}
}

// [data-toggle="collapse"][aria-expanded] {
// 	position: relative;
// 	cursor: pointer;
// 	&::before {
//     position: absolute;
//     content: '';
//     top: 50%;
//     right: 3px;
//     margin-top: -2px;
//     width: 16px;
//     height: 16px;
//     border-top: 2px solid #303031;
//     border-left: 2px solid #303031;
//     transform: rotate(45deg);
//   }
// }

// [data-toggle="collapse"][aria-expanded="true"]:before {
//   transform: rotate(45deg);
// }

// [data-toggle="collapse"][aria-expanded="false"]:before {
//   margin-top: -11px;
//   transform: rotate(-135deg);
// }
