header {
	display: flex;
	align-items: center;
	height: 80px;
	color: #fff;
	background-color: #dedfdf;
	z-index: 10;
}

.header-nav {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	// margin-right: -34px;
	font-family: Bebas, sans-serif;
	font-size: 24px;
	text-transform: uppercase;
	li {
		margin-right: 34px;
	}
	a {
		padding: 4px 12px;
		border-bottom: 2px solid currentColor;
		transition: color .3s;
		&.active, &:hover {
			color: #a8272e;
		}
	}
}
