.slick-list {
  // height: 100% !important;
  // min-width: 100%;
  display: flex;
  width: 100%;
  // overflow: visible;
}

.slick-track {
  display: flex;
  // min-width: 100%;
}

.slick-slide {
  float: none;
  height: auto;
}

.slick-initialized .slick-slide {
	display: flex;
}

.slick-dots {
	li {
		vertical-align: top;
		margin: 0 5px;
		width: 24px;
		height: 24px;
		padding: 6px 0;
		button {
			position: relative;
			width: 24px;
			height: 12px;
			background-color: #ffffff;
			transition: border-color .3s;
			&:before {
				display: none;
			}
		}
		&.slick-active {
	  	button {
	  		background-color: #b7161e;
	  	}
		}
	}
}

.slider-controls {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	.slick-dots {
    position: relative;
    margin: 0 10px;
    width: auto;
    bottom: 0;
	}
}

.slider-prev,
.slider-next, {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 0;
	color: #e8e8e8;
	text-align: center;
	cursor: pointer;
	transition: color .3s;
	&:hover {
		color: #ffde01;
	}
	&:before {
		font-family: "fontello";
		content: '\e80b';
		margin-left: -2px;
		font-size: 14px;
	}
}

.slider-next {
	&:before {
		content: '\e80c';
		margin-left: 2px;
	}
}

.slider {
	display: flex;
	flex-wrap: nowrap;
	// min-width: 0;
	min-width: 100%;
	// margin: 0;
	// margin: -30px;
	// padding: 30px 15px;
	overflow: hidden;
	> * {
		flex: 0 0 100%;
	}
}

.slick-slide {
	> div {
		flex-grow: 1;
		display: flex;
		min-width: 0;
		> [class*="col"] {
			display: flex !important;
	    	flex: 0 0 100%;
	    	max-width: 100%;
		}
	}
}

// .slider-card {
// 	display: flex;
// 	margin: 0 auto;
// 	max-width: 800px;
// 	.slider {
// 		flex-grow: 1;
// 		min-width: 0;
// 		margin-left: 20px;
// 	}
// 	.slide {
// 		height: 280px;
// 	}
// }

// .slider-vertical {
// 	width: 80px;
// 	min-width: 80px;
// 	height: 280px;
// 	overflow: hidden;
// 	&.slick-initialized {
// 		overflow: visible;
// 	}
// 	.slick-track {
// 		flex-direction: column;
// 	}
// 	.slick-current {
// 		.slide-vertical {
// 			border-color: red;
// 		}
// 	}
// }

// .slide-vertical {
// 	display: flex !important;
// 	align-items: center;
// 	justify-content: center;
// 	margin-bottom: 20px;
// 	width: 100%;
// 	height: 80px;
// 	border: 2px solid #777;
// 	background-color: gold;
// }
