.entrance {
	position: relative;
	width: 100%;
	display: flex;
	// flex-direction: column;
	justify-content: center;
	align-items: center;
	// background: center no-repeat;
	// background-size: cover;
}

.entrance__section {
	position: absolute;
	top: 0;
	width: 50%;
	height: 100%;
	background-repeat: no-repeat; 
	background-size: cover;
	&:first-child {
		left: 0;
		background-position: top left;
	}
	&:last-child {
		left: 50%;
		background-position: top right;
	}
}

.entrance__content {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.btn-entrance {
	min-width: 170px;
}